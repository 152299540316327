import React, {HTMLAttributes} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {Checkbox} from "@pg-design/checkbox";
import {borderRadius, mb, ml, onDesktop, p, pb} from "@pg-design/helpers-css";
import {RoomsIcon} from "@pg-design/icons";
import {Picture} from "@pg-design/picture";
import {Text} from "@pg-design/text";
import {pluralize} from "@pg-mono/string-utils";

import {RodoText} from "../lead/components/common/RodoText";
import {OfferType} from "../offer/types/OfferType";
import {flatsPluralize} from "../real_estate/constants/flats_pluralize";
import {IRecommendedOffer} from "../recommendations/types/IRecommendedOffer";
import {RecommendationCheckboxContainer, RecommendedListItemWrapper} from "./RecommendedPropertyItem";

type IOfferItemProps = {
    offer: IRecommendedOffer;
    checked: boolean;
    handleChange: (value: number) => void;
} & Omit<HTMLAttributes<HTMLLIElement>, "checked" | "onClick">;

export const RecommendOfferItem = (props: IOfferItemProps) => {
    const {offer, checked, handleChange, ...htmlProps} = props;

    const {
        id: offerID,
        configuration: {personal_data_processor_name, personal_data_processor_url, privacy_policy_url},
        vendor: {name},
        type
    } = offer;

    const pictureSource = [
        {minWidthPX: 0, src: offer.main_image.m_img_336x160, width: 336, height: 160},
        {minWidthPX: 1024, src: offer.main_image.m_img_277x160, width: 277, height: 160}
    ];

    const ariaLabelId = offerID + "checkbox";

    const theme = useTheme();

    return (
        <RecommendedListItemWrapper checked={checked} onClick={() => handleChange(offerID)} css={[mb(5), pb(2), onDesktop(mb(2), pb(0))]} {...htmlProps}>
            <div>
                <div css={offerListItem}>
                    <Picture sources={pictureSource} alt={`${offer.name}-${offerID}`} css={imgHolder} />

                    <div css={offerListItemText}>
                        <Text variant="body_copy_1" id={ariaLabelId} className="bt">
                            {offer.name}
                        </Text>

                        <Text color={theme.colors.gray[700]} variant="info_txt_1" mb="1.6rem">
                            {offer.address}
                        </Text>

                        {type === OfferType.FLAT && (
                            <FlatAndRoomsInfo flatsAmount={offer.property_count} roomsMin={offer.room_min} roomsMax={offer.room_max} css={mb(2)} />
                        )}

                        <RodoText
                            privacyPolicyUrl={privacy_policy_url}
                            privacyDataProcessorName={personal_data_processor_name}
                            personalDataProcessorUrl={personal_data_processor_url}
                            vendorName={name}
                        />
                    </div>
                </div>
            </div>

            <RecommendationCheckboxContainer checked={checked} onClick={stopPropagation}>
                <Checkbox
                    id={`${offerID}`}
                    name={`property_${offerID}`}
                    checked={checked}
                    value={offerID}
                    onChange={() => handleChange(offerID)}
                    aria-labelledby={ariaLabelId}
                    checkedMarkColor="#02D054"
                />
            </RecommendationCheckboxContainer>
        </RecommendedListItemWrapper>
    );
};

// Sub-components
interface IFlatAndRoomsInfoTextProps {
    mobile?: boolean;
    flatsAmount: number;
    roomsMin: number;
    roomsMax: number;
    className?: string;
}

const getPropertyRange = (min: number | null, max: number | null) => {
    if (!!min && !!max && min < max) {
        return `${min}-${max}`;
    }

    return max ?? min ?? null;
};

const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
};

const availablePluralize = pluralize(["dostępne", "dostępne", "dostępnych"]);

const FlatAndRoomsInfo = (props: IFlatAndRoomsInfoTextProps) => {
    const roomsRange = getPropertyRange(props.roomsMin, props.roomsMax);
    const roomsRangeText = roomsRange && <strong>{roomsRange} pokojowe</strong>;

    return (
        <div css={flatAndRoomsInfo} className={props.className}>
            <RoomsIcon size="2.4" />
            <Text variant="body_copy_2" css={ml(2)}>
                {`${props.flatsAmount}\u00A0${availablePluralize(props.flatsAmount)}\u00A0${flatsPluralize(props.flatsAmount)}: `}
                {roomsRangeText}
            </Text>
        </div>
    );
};

// Styles
const flatAndRoomsInfo = css`
    display: flex;
    align-items: center;
    margin-left: 0.7rem;
`;

const offerListItem = (theme: Theme) => css`
    display: flex;
    align-items: center;
    flex-direction: column;

    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }

    @media (min-width: ${theme.breakpoints.md}) {
        ${borderRadius(1, 0, 0, 1)};
        flex-direction: row;
    }
`;

const imgHolder = (theme: Theme) => css`
    ${borderRadius(1, 1, 0, 0)};
    overflow: auto;

    @media (min-width: ${theme.breakpoints.md}) {
        ${borderRadius(1, 0, 0, 1)};
    }
`;

const offerListItemText = () => css`
    ${p(2, 3, 2, 2)};
    display: flex;
    flex-direction: column;
    width: 100%;
`;
