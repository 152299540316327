import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const MapIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="m8.467 1.744-1.943.891-.165.075-.368-.169-1.9-.871-.054-.025-.185-.084-.083-.038-.083.038-.186.084-.053.025-1.914.878L1 2.792V8.74l.533-.24 1.969-.9.267-.122.266.122 1.684.772.272.124H6l.358.164L7 8.365l1.468-.673L9 7.448V1.5l-.533.244Zm-6.934 1.39L3.5 2.232v4.682l-.053.117-1.914.878V3.134Zm2.556 3.9-.054-.117V2.232L5.719 3l.272.124V7.9l-1.902-.866Zm4.378-3.358v3.43L6.524 8V3.221L7 3l1.467-.669v1.345Z" />
        </SvgIcon>
    );
};
