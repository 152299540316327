import React, {useEffect, useMemo, useState} from "react";
import {css} from "@emotion/react";

import {CenterBox} from "@pg-design/grid";
import {h100, m, maxHeight, onDesktop, p, position} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";

import {IMarketplaceArchitectOfferSummary} from "../api/fetch_marketplace_architect_promoted_offers_by_region";
import {useReadArchitectDetailsQuery} from "../api/read_architect_details";
import {useArchitectsFromRegion} from "../hooks/use_architects_from_region";
import {architectMultileadTracking, MultileadGTMEvent} from "../tracking/architect_multilead_tracking";
import {IArchitectListItem} from "../types/IArchitectListItem";
import {IArchitectUserLeadData} from "../types/IArchitectUserLeadData";
import {MultileadTrackingActionType} from "../types/MultileadTrackingActionType";
import {getArchitectApplicationStorageList} from "../utils/architect_application_storage";
import {MultileadArchitectList} from "./architect_multilead_parts/MultileadArchitectList";
import {IArchitectOfferSummary, MultileadArchitectSuccess} from "./architect_multilead_parts/MultileadArchitectSuccess";
import {MultileadArchitectTopSection} from "./architect_multilead_parts/MultileadArchitectTopSection";
import {MultiLeadArchitectUserDataForm} from "./architect_multilead_parts/MultiLeadArchitectUserDataForm";

interface IProps {
    region?: string;
    userData: {
        name: string;
        email: string;
        phone: string;
        area?: string | undefined;
    };
    mainAppliedArchitectOffer: IMarketplaceArchitectOfferSummary | null;
}

export const ArchitectMultileadModalContent = (props: IProps) => {
    const {mainAppliedArchitectOffer, region} = props;
    const appliedArchitectsList = getArchitectApplicationStorageList();
    const {architectsFromRegion, isLoading: isArchitectsFromRegionLoading} = useArchitectsFromRegion(region);
    const {data: mainOfferArchitectDetails} = useReadArchitectDetailsQuery(mainAppliedArchitectOffer?.id || 0, {skip: !mainAppliedArchitectOffer?.id});

    const architectList = useMemo(
        () =>
            architectsFromRegion?.filter(
                (architectItem) => architectItem.id !== mainAppliedArchitectOffer?.id && !appliedArchitectsList.includes(architectItem.id)
            ) || [],
        [architectsFromRegion, appliedArchitectsList, mainAppliedArchitectOffer]
    );
    const [isDataChanging, setIsDataChanging] = useState<boolean>(false);
    const [isSuccessfulSubmit, setIsSuccessfulSubmit] = useState<boolean>(false);
    const [userData, setUserData] = useState<IArchitectUserLeadData>(() => ({
        name: props.userData?.name || "",
        email: props.userData?.email || "",
        phone: props.userData?.phone || "",
        area: ""
    }));
    const [appliedList, setAppliedList] = useState<IArchitectOfferSummary[]>([]);

    useEffect(() => {
        if (!isArchitectsFromRegionLoading && architectList.length === 0 && mainAppliedArchitectOffer) {
            setAppliedList([
                ...(mainOfferArchitectDetails && mainAppliedArchitectOffer
                    ? [
                          {
                              name: mainAppliedArchitectOffer.name,
                              min_price_m2: mainAppliedArchitectOffer.min_price_m2,
                              offer: {
                                  description: mainOfferArchitectDetails.offer.description
                              }
                          }
                      ]
                    : [])
            ]);
            setIsSuccessfulSubmit(true);
        }
    }, [isArchitectsFromRegionLoading, mainAppliedArchitectOffer?.id]);

    useEffect(() => {
        architectMultileadTracking.gtm.architectMultileadEvent(MultileadGTMEvent.ARCHITECT_MULTILEAD, {
            architect_multilead_action: MultileadTrackingActionType.VIEW,
            architect_multilead_count: architectList.length
        });

        /**
         reset states in case of second time open on same view
         */
        if (isSuccessfulSubmit) {
            setIsSuccessfulSubmit(false);
        }

        if (appliedList.length !== 0) {
            setAppliedList([]);
        }
    }, []);

    useEffect(() => {
        if (props.userData) {
            setUserData({
                name: props.userData?.name || "",
                email: props.userData?.email || "",
                phone: props.userData?.phone || "",
                area: ""
            });
        }
    }, [props.userData.name, props.userData.email, props.userData.phone]);

    const onLeadDataChange = (values: IArchitectUserLeadData) => {
        setUserData(values);
        setIsDataChanging(false);
    };

    const onAfterApplication = (architectList: IArchitectListItem[]) => {
        setAppliedList([
            ...(mainOfferArchitectDetails && mainAppliedArchitectOffer
                ? [
                      {
                          name: mainAppliedArchitectOffer.name,
                          min_price_m2: mainAppliedArchitectOffer.min_price_m2,
                          offer: {
                              description: mainOfferArchitectDetails.offer.description
                          }
                      }
                  ]
                : []),
            ...architectList
        ]);
        setIsSuccessfulSubmit(true);
    };

    return (
        <div css={p(3)}>
            {isArchitectsFromRegionLoading ? (
                <CenterBox height="40rem" css={loaderWrapperStyle}>
                    <Loader size="lg" />
                </CenterBox>
            ) : (
                <div css={modalContentWrapper}>
                    <MultileadArchitectTopSection
                        openDataChanging={() => {
                            setIsDataChanging(true);
                        }}
                        isSuccessfulSubmit={isSuccessfulSubmit}
                    />

                    {!isSuccessfulSubmit && !isDataChanging && (
                        <MultileadArchitectList architectList={architectList} userData={userData} onAfterApplication={onAfterApplication} />
                    )}

                    {isDataChanging && <MultiLeadArchitectUserDataForm leadData={userData} onLeadDataChange={onLeadDataChange} />}

                    {isSuccessfulSubmit && <MultileadArchitectSuccess appliedList={appliedList} />}
                </div>
            )}
        </div>
    );
};

const loaderWrapperStyle = css`
    width: 70rem;
`;

const modalContentWrapper = css`
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
    ${h100};
    ${position("relative")}

    ${onDesktop(maxHeight("600px"), m(0))};
`;
