import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const InfoIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M5.266 4.2h-.533v2.667h.533V4.2ZM4.733 3.133h.533v.533h-.533v-.533Z" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5 1a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 7.467a3.467 3.467 0 1 1 0-6.934 3.467 3.467 0 0 1 0 6.934Z" />
        </SvgIcon>
    );
};
