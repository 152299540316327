import React, {useEffect, useMemo, useState} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {backgroundColor, borderRadius, zIndex} from "@pg-design/helpers-css";

interface IProps {
    cardId: string | number;
    maxStockLength?: number;
    className?: string;
}

export const MultiLeadCardStockEffect = (props: IProps) => {
    const theme = useTheme();
    const [animationInProgress, setAnimationInProgress] = useState(false);
    const [cardCount, setCardCount] = useState(0);

    useEffect(() => {
        setAnimationInProgress(true);

        setTimeout(() => {
            setAnimationInProgress(false);
            setCardCount(Number.isFinite(props.maxStockLength) ? Number(props.maxStockLength) : 3);
        }, 550);
    }, [props.cardId]);

    const cardStock = useMemo(
        () =>
            [
                {
                    backgroundColor: theme.colors.gray[300]
                },
                {
                    backgroundColor: theme.colors.gray[200]
                },
                {
                    backgroundColor: theme.colors.gray[100]
                }
            ].slice(0, cardCount),
        [cardCount]
    );

    return (
        <div css={cardsWrapperStyles} className={props.className}>
            {cardStock.map((cardValue, index) => (
                <div
                    key={index}
                    css={[
                        singleCardStyle,
                        backgroundColor(cardValue.backgroundColor),
                        zIndex(10 - index),
                        animationStyles(index + 1),
                        ...(animationInProgress
                            ? [animationInProgressStyle, animationStyles(index), backgroundColor(cardStock[index - 1]?.backgroundColor || "#fff")]
                            : [])
                    ]}
                />
            ))}
        </div>
    );
};

const cardsWrapperStyles = (theme: Theme) => css`
    position: absolute;
    z-index: 1;
    inset: 0 0.5rem 0 0;

    @media (min-width: ${theme.breakpoints.md}) {
        inset: 0.5rem 0 0;
    }
`;

const singleCardStyle = (theme: Theme) => css`
    position: absolute;
    inset: 0;
    ${borderRadius(2)};
    transform-origin: bottom;
    animation: opacityAnimation 0.3s linear;

    @keyframes opacityAnimation {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @media (min-width: ${theme.breakpoints.md}) {
        transform-origin: left;
    }
`;

const animationInProgressStyle = css`
    transition: all 0.5s ease-in-out;
`;

const animationStyles = (animationIndex: number) => (theme: Theme) => css`
    transform: scale(${1 - animationIndex * 0.08});
    margin-bottom: -${animationIndex * 1.6}rem;

    @media (min-width: ${theme.breakpoints.md}) {
        margin-bottom: unset;
        margin-left: -${animationIndex * 1.6}rem;
    }
`;
