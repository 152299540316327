import {IApplication} from "../../application/types/IApplication";
import {IMultiLeadData, MultiLeadStatus} from "../../application/types/IMultiLeadData";
import {IMultileadSummaryStepState} from "../../application/types/IMultileadSummaryStepState";
import {IOriginalApplicationProperty} from "../../application/types/IOriginalApplicationProperty";

interface IHandleMultileadCloseParams {
    originalApplicationResponse: IApplication | null;
    sentLeads: IMultiLeadData[];
    multiLeadProperty: IOriginalApplicationProperty;
    handleSetMultiLeadSummaryStep: (payload: IMultileadSummaryStepState) => void;
    handleSetAfterSummaryStep: (originalApplicationResponse: IApplication | null) => void;
    handleModalClose: () => void;
    onSetNoSummaryStep?: () => void;
}

export function handleMultiLeadClose(params: IHandleMultileadCloseParams) {
    const {
        originalApplicationResponse,
        sentLeads,
        multiLeadProperty,
        handleSetMultiLeadSummaryStep,
        handleSetAfterSummaryStep,
        handleModalClose,
        onSetNoSummaryStep
    } = params;

    const hasAnyApplicationBesideOriginal = sentLeads.length > 1;
    const isOnlyOriginalApplicationAndNotBeenRejected = sentLeads.length === 1 && sentLeads[0].status !== MultiLeadStatus.REJECTED;

    const shouldSetSummaryStep = hasAnyApplicationBesideOriginal || isOnlyOriginalApplicationAndNotBeenRejected;
    const shouldSetAfterSummaryStep = sentLeads.length > 0;

    if (shouldSetSummaryStep) {
        handleSetMultiLeadSummaryStep({
            multiLeadSummaryItems: sentLeads,
            multiLeadProperty
        });

        return;
    } else if (shouldSetAfterSummaryStep) {
        handleSetAfterSummaryStep(originalApplicationResponse);
    } else {
        /**
         * Current logic is - if no applications/leads or original application rejected (without leads)-> show APPLIED_RECOMMENDED_PROPERTIES step
         * TODO: May be worth to consult with business about this,
         *  because if we haven't applied to multiple leads, and original one failed,
         *  it makes more sens to show different step then "summary" or to simply close modal (force close)
         */
        handleModalClose();
    }

    if (!shouldSetSummaryStep && onSetNoSummaryStep) {
        onSetNoSummaryStep();
    }
}
