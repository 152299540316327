import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {FormikForm, handleFormikPost, IFormikSubmitFn} from "@pg-design/formik-utils";
import {flex, h100, m, p, pb, w100} from "@pg-design/helpers-css";
import {CloseIcon} from "@pg-design/icons";
import {Link} from "@pg-design/link";
import {Popover} from "@pg-design/notifications";
import {Text} from "@pg-design/text";

import {hitMultileadSendButton} from "../../../../lead/tracking/hit_multilead_send_button";
import {resetRecommendedOffers} from "../../../../offer/detail/actions/fetch_recommended_offers";
import {resetRecommendedProperties} from "../../../../property/actions/fetch_recommended_properties";
import {IRecommendedProperty} from "../../../../recommendations/types/IRecommendedProperty";
import {ViewType} from "../../../../view_type/ViewType";
import {applicationFormValidationSchema} from "../../../constants/application_form";
import {MAX_DISTANCE_FROM_ORIGINAL_OFFER_KM_ALL_REGIONS, MAX_DISTANCE_FROM_ORIGINAL_OFFER_KM_WARSAW} from "../../../constants/max_distance";
import {WARSAW_REGION_ID} from "../../../constants/region_ids";
import {useEmailVerification} from "../../../hooks/use_email_verification";
import {useMultiLeadData} from "../../../hooks/use_multi_lead_data";
import {useMultiLeadFormSend} from "../../../hooks/use_multi_lead_form_send";
import {useRecommendationsSelection} from "../../../hooks/use_recommendations_selection";
import {IApplicationFormValues} from "../../../types/IApplicationFormValues";
import {MultiLeadRecommendationType} from "../../../types/MultiLeadRecommendationType";
import {ApplicationSourceSection} from "../../../utils/ApplicationSource";
import {report} from "../../../utils/recommended_application/analytics_events";
import {ApplicationUserDataFormFields} from "../common/ApplicationUserDataFormFields";
import {RecommendedList} from "./parts/RecommendedList";
import {SameOfferRecommendedProperties} from "./parts/SameOfferRecommendedProperties";

// Constant values
const MAX_SHOWN_CURLED = 3;

export interface IMultiLeadSimpleRecommendationsModalProps {
    sourceSection: ApplicationSourceSection;
    onModalClose: () => void;
    onSuccessfulSubmit?: () => void;
    storeHash: string;
    recommendationType: MultiLeadRecommendationType;
    originalApplicationFormValues: IApplicationFormValues;
    recommendationsWithPriceFilter: boolean;
    sameOfferRecommendedProperties: boolean;
    customSourceSection?: string;
}

const calcMaxRecommendations = (recommendations: IRecommendedProperty[], originApplicationRegionCityID: number | undefined) => {
    const maxRecommendationDistance =
        originApplicationRegionCityID && originApplicationRegionCityID === WARSAW_REGION_ID
            ? MAX_DISTANCE_FROM_ORIGINAL_OFFER_KM_WARSAW
            : MAX_DISTANCE_FROM_ORIGINAL_OFFER_KM_ALL_REGIONS;

    const baseRecommendationsGroupAmount = recommendations.filter((recommendation) => recommendation.distance < maxRecommendationDistance).length;

    return baseRecommendationsGroupAmount > 0 && baseRecommendationsGroupAmount < MAX_SHOWN_CURLED ? baseRecommendationsGroupAmount : MAX_SHOWN_CURLED;
};

export const MultiLeadSimpleRecommendationsModal = (props: IMultiLeadSimpleRecommendationsModalProps) => {
    const dispatch = useDispatch();
    const {viewType, originalApplicationRegionCityID, originalApplicationGeoPoint, recommendedProperties, recommendedOffers, originalApplicationCountry} =
        useMultiLeadData(props);

    const [showPropertiesList, setShowPropertiesList] = useState(true);
    const [isMultiLeadSubmitting, setIsMultiLeadSubmitting] = useState(false);

    const maxRecommendationsShownOnCurl = calcMaxRecommendations(recommendedProperties, originalApplicationRegionCityID);

    const {selectedRecommendationsCount, selectedRecommendations, toggleRecommendation} = useRecommendationsSelection({
        recommendedOffers,
        recommendedProperties,
        maxRecommendationsShown: maxRecommendationsShownOnCurl,
        recommendationType: props.recommendationType
    });

    const isSendApplicationDisabled = selectedRecommendationsCount === 0;

    useEffect(() => {
        recommendedOffers.slice(3).map((el) => toggleRecommendation(el.id));
        return () => {
            // Clear recommended properties, offers and form values - we don't need this data anymore
            dispatch(resetRecommendedProperties());
            dispatch(resetRecommendedOffers());
        };
    }, []);

    const {sendMultiLeadForm} = useMultiLeadFormSend(props);
    const {props: emailNotificationProps, validateEmailOnce} = useEmailVerification();

    const onSubmit: IFormikSubmitFn<IApplicationFormValues> = async (formValues, formikHelpers) => {
        if (formValues?.email && !(await validateEmailOnce(formValues?.email))) {
            return;
        }

        setIsMultiLeadSubmitting(true);

        handleFormikPost(
            sendMultiLeadForm({
                selectedRecommendations,
                formValues
            }),
            formikHelpers,
            {
                onSuccess: () => {
                    if (props.recommendationType === MultiLeadRecommendationType.PROPERTY) {
                        props.onSuccessfulSubmit && props.onSuccessfulSubmit();
                    }

                    if (props.recommendationType === MultiLeadRecommendationType.OFFER) {
                        props.onModalClose();
                    }
                },
                onFieldError: () => setIsMultiLeadSubmitting(false),
                onNonFieldError: () => setIsMultiLeadSubmitting(false)
            }
        );
    };

    const handleChange = (viewType: ViewType, recommendationId: number) => {
        const isCheckboxSelected = toggleRecommendation(recommendationId);
        // GTM & Algolytics events
        report.checkboxChange(props.recommendationType, viewType, isCheckboxSelected, recommendationId);
    };

    const handlePinClick = (viewType: ViewType | null, recommendationId: number) => {
        if (!(props.recommendationType in MultiLeadRecommendationType)) {
            throw new Error("Unknown recommendation type");
        }

        const isCheckboxSelected = toggleRecommendation(recommendationId);
        report.mapPinClick(viewType, recommendationId, isCheckboxSelected);
    };

    const onSendClick = () => {
        hitMultileadSendButton();
    };

    return (
        <div css={holder}>
            <FormikForm
                onSubmit={onSubmit}
                initialValues={props.originalApplicationFormValues}
                validationSchema={applicationFormValidationSchema}
                css={props.sameOfferRecommendedProperties && h100}
            >
                {() => {
                    return props.sameOfferRecommendedProperties ? (
                        <SameOfferRecommendedProperties
                            isSubmitting={isMultiLeadSubmitting}
                            onClose={props.onModalClose}
                            onSelect={handleChange}
                            recommendedProperties={recommendedProperties}
                            selected={selectedRecommendations}
                        />
                    ) : (
                        <div css={innerFormStyle}>
                            <div css={headerSectionStyle}>
                                <Text variant="headline_6" css={[m(0, 4, 1, 0)]}>
                                    Wybierz i zapytaj o podobne oferty w najbliższej okolicy.
                                </Text>

                                <div css={topPanelWrapper}>
                                    <Link underline onClick={() => setShowPropertiesList((prevState) => !prevState)}>
                                        {showPropertiesList ? "Zmień dane zapytania" : "Wybierz dodatkowe oferty"}
                                    </Link>
                                </div>
                            </div>
                            <div css={listSectionStyle}>
                                {showPropertiesList ? (
                                    <RecommendedList
                                        recommendationType={props.recommendationType}
                                        recommended={{
                                            properties: recommendedProperties,
                                            offers: recommendedOffers
                                        }}
                                        originalApplicationGeoPoint={originalApplicationGeoPoint}
                                        originalApplicationCountry={originalApplicationCountry}
                                        viewType={viewType}
                                        maxShowOnCurl={maxRecommendationsShownOnCurl}
                                        selected={selectedRecommendations}
                                        handleChange={handleChange}
                                        handlePinClick={handlePinClick}
                                    />
                                ) : (
                                    <ApplicationUserDataFormFields emailNotificationProps={emailNotificationProps} />
                                )}
                            </div>

                            <div css={bottomPanelStyle}>
                                <Popover
                                    arrow
                                    isActive={isSendApplicationDisabled}
                                    popoverPlace="top"
                                    body="Zaznacz propozycje powyżej aby wysłać zapytanie"
                                    toggleHoverHandlers
                                    toggleOnClickOutside
                                    toggleClickHandler
                                >
                                    <SubmitButton
                                        variant="filled_primary"
                                        disabled={isSendApplicationDisabled || isMultiLeadSubmitting}
                                        isLoading={isMultiLeadSubmitting}
                                        onClick={onSendClick}
                                    >
                                        WYŚLIJ ZAPYTANIE
                                    </SubmitButton>
                                </Popover>
                            </div>

                            <div css={closeHolder} onClick={props.onModalClose}>
                                <CloseIcon size="2.4" />
                            </div>
                        </div>
                    );
                }}
            </FormikForm>
        </div>
    );
};

const holder = (theme: Theme) => css`
    ${p(2)};
    position: relative;
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(3)};
        height: 100%;
    }
`;

const innerFormStyle = css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
`;

const headerSectionStyle = css``;

const listSectionStyle = (theme: Theme) => css`
    display: flex;
    overflow-y: auto;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.md}) {
        flex: 0 0 450px;
    }
`;

const bottomPanelStyle = (theme: Theme) => css`
    ${p(2, 0, 2, 0)};
    ${flex("center")};
    flex-direction: column;
    ${w100};
    background-color: #fff;
    bottom: 0;
    left: 0;
    width: 100%;
    position: fixed;
    z-index: 2;

    @media (min-width: ${theme.breakpoints.sm}) {
        position: static;
    }
`;

const topPanelWrapper = css`
    ${pb(3)};
    ${flex("normal", "space-between")};
`;

const closeHolder = css`
    cursor: pointer;
    position: absolute;
    top: 2rem;
    right: 2.4rem;
`;

const SubmitButton = styled(FormikForm.Button)`
    width: 100%;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        min-width: 20rem;
    }
`;
