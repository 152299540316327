//  TODO: If possible, use PropertyListItem inside this component for content and leave only checkbox logic

import React, {HTMLAttributes} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {Checkbox} from "@pg-design/checkbox";
import {elevation} from "@pg-design/elevation";
import {borderRadius, m, mb, ml, mr, onDesktop, p, pointer} from "@pg-design/helpers-css";
import {CalendarCheckIcon} from "@pg-design/icons";
import {Image} from "@pg-design/image";
import {Text} from "@pg-design/text";
import {areaFormat, dateTimeFormat, formatFutureDate, pluralize} from "@pg-mono/string-utils";

import {RodoText} from "../lead/components/common/RodoText";
import {IRecommendedProperty} from "../recommendations/types/IRecommendedProperty";

type IProps = {
    property: IRecommendedProperty;
    offerType: "Mieszkanie" | "Dom" | "Lokal użytkowy";
    checked: boolean;
    handleChange: (value: number) => void;
    onHover?: (propertyID: null | number) => void;
} & Omit<HTMLAttributes<HTMLLIElement>, "checked" | "onClick" | "onMouseEnter" | "onMouseLeave" | "property">;

interface IThemeProps {
    isActiveOnHover?: boolean;
    checked: boolean;
}

//To make whole container clickable = prevent event propagation from checkbox and
//meet TS types in Checkbox onChange function
const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
};

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

export const RecommendedPropertyItem = (props: IProps) => {
    const {property, offerType, checked, handleChange, onHover, ...htmlProps} = props;
    const propertyID = property.id;

    const handleOnHover = () => {
        onHover && onHover(propertyID);
    };

    const onMouseOut = () => {
        props.onHover && props.onHover(null);
    };

    const ariaLabel = property.id + "label";

    const theme = useTheme();

    return (
        <RecommendedListItemWrapper
            checked={checked}
            isActiveOnHover={!!props.onHover}
            onClick={() => handleChange(propertyID)}
            onMouseEnter={handleOnHover}
            onMouseLeave={onMouseOut}
            css={wrapperStyles}
            {...htmlProps}
        >
            <div css={imageAndDataContainer}>
                <Image
                    css={propertyImage}
                    src={property.offer?.main_image?.m_img_350x230}
                    width="123px"
                    height="80px"
                    ratio={{xs: "123:80"}}
                    alt={`${property.offer.vendor.name}-${property.id}`}
                />

                <div css={propertyDataContainer}>
                    <Text variant="body_copy_1" id={ariaLabel}>{`${offerType}, ${property.rooms} ${roomsPluralize(property.rooms)}, ${areaFormat(
                        property.area
                    )}`}</Text>
                    <Text color={theme.colors.gray[700]} variant="info_txt_3" mb="0.8rem" className="bt">
                        {property.offer.name}, {property.offer.address}
                    </Text>

                    {property.offer?.construction_date_range && property.distance && (
                        <div css={pictogramsContainer}>
                            <CalendarCheckIcon size="1.7" />
                            <Text variant="info_txt_2" css={ml(1)}>
                                {formatFutureDate(property.offer.construction_date_range.upper, dateTimeFormat.quarterLong)}
                            </Text>
                        </div>
                    )}
                </div>
            </div>

            <RodoText
                vendorName={property.offer.vendor.name}
                privacyPolicyUrl={property.offer.configuration.privacy_policy_url}
                privacyDataProcessorName={property.offer.configuration.personal_data_processor_name}
                personalDataProcessorUrl={property.offer.configuration.personal_data_processor_url}
                css={[p(2, 1, 3, 1), onDesktop(p(2, 1))]}
            />

            <RecommendationCheckboxContainer checked={checked} isActiveOnHover={!!props.onHover} onClick={stopPropagation}>
                <Checkbox
                    id={`${property.id}`}
                    name={`property_${propertyID}`}
                    checked={checked}
                    value={propertyID}
                    onChange={() => handleChange(propertyID)}
                    aria-labelledby={ariaLabel}
                    checkedMarkColor="#02D054"
                />
            </RecommendationCheckboxContainer>
        </RecommendedListItemWrapper>
    );
};

export const RecommendedListItemWrapper = styled.li<IThemeProps>`
    ${borderRadius()};
    ${pointer};
    width: 100%;
    position: relative;
    border: 1px solid ${(props) => props.theme.colors.gray[500]};
    max-width: 335px;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        max-width: unset;
        flex-direction: row;
    }

    ${(props) =>
        props.checked &&
        css`
            border: 1px solid transparent;
            ${elevation(3)};
        `};

    ${(props) =>
        props.isActiveOnHover &&
        css`
            &:hover {
            }
        `}
`;

const imageAndDataContainer = css`
    display: flex;
`;

const propertyImage = css`
    ${borderRadius(1, 0, 1, 0)}
    ${mr(1)};
    flex: 0 0 123px;
    overflow: hidden;
`;

const propertyDataContainer = (theme: Theme) => css`
    ${m(1, 1, 0, 0)};
    display: flex;
    flex-direction: column;

    @media (min-width: ${theme.breakpoints.sm}) {
        width: 65%;
        padding: 0;
    }
`;

const pictogramsContainer = css`
    display: flex;
    align-items: center;
`;

export const RecommendationCheckboxContainer = styled.div<IThemeProps>`
    width: 5.2rem;
    height: 5.2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.theme.colors.gray[100]};
    border: 1px solid ${(props) => props.theme.colors.gray[500]};
    position: absolute;
    bottom: -3rem;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: ${(props) => props.theme.breakpoints.sm}) {
        right: -2.5rem;
        top: 50%;
        transform: translateY(-50%);

        bottom: unset;
        left: unset;
    }
    ${(props) =>
        props.checked &&
        css`
            border: none;
            box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.19);
        `};
`;

const wrapperStyles = (theme: Theme) => css`
    ${mb(6)};
    max-width: 60rem;

    @media (min-width: ${theme.breakpoints.md}) {
        ${mb(2)};
        max-width: 69.7rem;
    }
`;
