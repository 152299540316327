import {priceFormat} from "@pg-mono/string-utils";

import {getPercentageOfPrice, roundPriceToNearestTen} from "../../property/utils/estimated_price_utils";

interface IParams {
    propertyPrice?: number | null;
    // Data taken from BigData
    estimatedPropertyPriceFrom?: number;
    estimatedPropertyPriceTo?: number;
}
export const getPropertyPriceRange = (params: IParams) => {
    const {estimatedPropertyPriceFrom, estimatedPropertyPriceTo, propertyPrice} = params;

    const propertyPriceFrom = propertyPrice || estimatedPropertyPriceFrom;
    const propertyPriceTo = propertyPrice ? propertyPrice + getPercentageOfPrice(propertyPrice, 10) : estimatedPropertyPriceTo;

    return {
        propertyPriceFrom: propertyPriceFrom ? priceFormat(roundPriceToNearestTen(propertyPriceFrom)) : null,
        propertyPriceTo: propertyPriceTo ? priceFormat(roundPriceToNearestTen(propertyPriceTo)) : null
    };
};
