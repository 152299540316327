import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {IApplicationOwnProps} from "../../application/components/Application";
import {initialEmptyApplicationValues} from "../../application/constants/application_form";
import {IApplicationData} from "../../application/reducers/application_reducer";
import {IApplicationFormValues} from "../../application/types/IApplicationFormValues";
import {ApplicationSource} from "../../application/utils/ApplicationSource";
import {generateApplicationText} from "../../application/utils/generate_application_text";
import {IRealEstateIds} from "../types/IRealEstateIds";
import {calculateRoomsField} from "../utils/calculate_room_fields";
import {getLeadFormValuesFromProfile} from "../utils/get_lead_form_values_from_profile";
import {getLeadFormValuesFromStorage} from "../utils/get_lead_form_values_from_storage";

interface IUseInitialLeadFormValuesParams {
    storeHash: string;
    realEstateIds: IRealEstateIds;
    source: ApplicationSource;
    promotion: IApplicationOwnProps["promotion"];
    preventStorageOfferSearchLoad: IApplicationOwnProps["preventStorageOfferSearchLoad"];
    leadData: IApplicationData | null;
    resetValues?: boolean;
    customFormText?: string;
}

export function useInitialLeadFormValues(params: IUseInitialLeadFormValuesParams) {
    const {realEstateIds, source, promotion, preventStorageOfferSearchLoad, resetValues, leadData} = params;
    const {propertyId} = realEstateIds;

    const [initialLeadFormValues, setInitialLeadFormValues] = useState<IApplicationFormValues | null>(null);

    const isAuthenticated = useSelector((state: IRPStore) => state.isAuthenticated);
    const profile = useSelector((state: IRPStore) => state.user.profile);
    const sentLeadFormValues = useSelector((state: IRPStore) => state.sentLead.storedValues);
    const userPreferencesFilters = useSelector((state: IRPStore) => state.user.preferences?.data?.filters) || {};

    const updateInitialLeadFormValues = () => {
        const formValuesFromStorage = sentLeadFormValues && getLeadFormValuesFromStorage(sentLeadFormValues, initialEmptyApplicationValues);

        const offer = leadData?.offer?.detail;
        const property = leadData?.property?.detail;

        const textGenerateProps = {
            source,
            offerName: (offer && offer.name) || null,
            propertyNumber: (property && property.number) || null,
            propertyPrice: (property && property.price) || null,
            promotionType: promotion && promotion.type,
            customText: offer && offer.custom_form_text
        };

        if (leadData && isAuthenticated && profile.data) {
            const formValuesFromProfile = getLeadFormValuesFromProfile(profile.data, initialEmptyApplicationValues, sentLeadFormValues);

            setInitialLeadFormValues({
                ...formValuesFromProfile,
                rooms: calculateRoomsField({
                    offer,
                    propertyId,
                    preventStorageOfferSearchLoad,
                    userPreferencesFilters
                }),
                text: params.customFormText || generateApplicationText(textGenerateProps)
            });
        }

        if (leadData && !isAuthenticated && formValuesFromStorage) {
            setInitialLeadFormValues({
                ...initialEmptyApplicationValues,
                ...formValuesFromStorage,
                rooms: calculateRoomsField({
                    offer,
                    propertyId,
                    preventStorageOfferSearchLoad,
                    userPreferencesFilters
                }),
                text: params.customFormText || generateApplicationText(textGenerateProps)
            });
        }

        if (leadData && !isAuthenticated && !formValuesFromStorage) {
            setInitialLeadFormValues({
                ...initialEmptyApplicationValues,
                rooms: calculateRoomsField({
                    offer,
                    propertyId,
                    preventStorageOfferSearchLoad,
                    userPreferencesFilters
                }),
                text: params.customFormText || generateApplicationText(textGenerateProps)
            });
        }
    };

    useEffect(() => {
        if (!initialLeadFormValues) {
            updateInitialLeadFormValues();
        }
    }, [leadData, isAuthenticated]);

    useEffect(() => {
        updateInitialLeadFormValues();
    }, [sentLeadFormValues, profile.data]);

    useEffect(() => {
        if (resetValues && initialLeadFormValues) {
            setInitialLeadFormValues(null);
        }
    }, [resetValues]);

    useEffect(() => {
        if (!resetValues && !initialLeadFormValues) {
            updateInitialLeadFormValues();
        }
    }, [resetValues]);

    return initialLeadFormValues;
}
