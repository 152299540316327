import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";
import {MultileadTrackingActionType} from "../types/MultileadTrackingActionType";

export enum MultileadGTMEvent {
    ARCHITECT_MULTILEAD = "architect_multilead"
}

const architectMultileadEvent = (
    event: MultileadGTMEvent,
    data: {architect_multilead_action: MultileadTrackingActionType; architect_multilead_count?: number}
) => {
    const eventData = {event, ...data};

    hitGoogleTagManager(eventData);
};

export const architectMultileadTracking = {
    gtm: {
        architectMultileadEvent
    }
};
