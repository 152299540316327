import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum ArchitectModalGTMEventAction {
    SHOW_OFFER = "show_offer",
    MODAL_CLOSE = "price_modal_close",
    GO_TO_OTHER_OFFERS = "other_offers",
    OFFER_ASK = "offer_ask"
}

const architectModalEvent = (action: ArchitectModalGTMEventAction) => {
    hitGoogleTagManager({event: "architect_modal", architect_action: action});
};

export const architectModalAnalytics = {
    gtm: {
        architectModalEvent
    }
};
