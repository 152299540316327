import {useState} from "react";

import {initialEmptyApplicationValues} from "../constants/application_form";
import {IApplicationFormValues} from "../types/IApplicationFormValues";

interface IProps {
    originalApplicationFormValues: IApplicationFormValues;
}

export const useApplicationForm = (props: IProps) => {
    const {originalApplicationFormValues} = props;

    const [applicationUserData, setApplicationUserData] = useState<IApplicationFormValues>({
        ...initialEmptyApplicationValues,
        ...(originalApplicationFormValues
            ? {
                  name: originalApplicationFormValues.name,
                  email: originalApplicationFormValues.email,
                  phone: originalApplicationFormValues.phone
              }
            : {})
    });

    const onUserDataFormSubmit = (formValues: IApplicationFormValues) => {
        setApplicationUserData({
            ...formValues
        });
    };

    return {
        applicationUserData,
        onUserDataFormSubmit
    };
};
