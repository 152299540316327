import {areaFormat} from "@pg-mono/string-utils";

export const getAreaComparisonText = (baseArea: number, comparedArea: number) => {
    if (baseArea === comparedArea) {
        return "identyczna powierzchnia";
    }

    if (comparedArea > baseArea) {
        return `większe o ${areaFormat(Math.ceil(comparedArea - baseArea))}`;
    }

    return `mniejsze o ${areaFormat(Math.ceil(baseArea - comparedArea))}`;
};
