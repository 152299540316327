import {hitAlgolytics} from "@pg-mono/algolytics";

import {MultiLeadRecommendationType} from "../../application/types/MultiLeadRecommendationType";
import {getTrackedSiteData} from "../../tracking/algolytics_hits/tracked_utils/tracked_site";
import {ITrackedSiteData} from "../../tracking/types/ITrackedSiteData";
import {ViewType} from "../../view_type/ViewType";
import {MultileadVersion} from "../types/MultileadVersion";
import {RecommendationSource} from "../types/RecommendationSource";

interface IHitMultileadRecommendationShownParams {
    recommendationId: number | string | null;
    recommendationType: MultiLeadRecommendationType;
    recommendationSource: RecommendationSource;
    multileadVersion: MultileadVersion;
    viewType?: ViewType | null;
}

type IHitMultileadRecommendationShownPayload = {
    recommendation_source: RecommendationSource;
    multilead_version: MultileadVersion;
    recommended_property_id: IRecommendationId;
    recommended_offer_id: IRecommendationId;
    recommended_vendor_id: IRecommendationId;
    view_type?: ViewType;
} & ITrackedSiteData;

type IRecommendationId = number | string | null;

export const hitMultileadRecommendationShown = (params: IHitMultileadRecommendationShownParams) => {
    const {recommendationId, recommendationType, recommendationSource, multileadVersion, viewType} = params;

    const payload: IHitMultileadRecommendationShownPayload = {
        ...getTrackedSiteData(),
        ...(viewType ? {view_type: viewType} : {}),
        recommendation_source: recommendationSource,
        multilead_version: multileadVersion,
        recommended_property_id: recommendationType === MultiLeadRecommendationType.PROPERTY ? recommendationId : null,
        recommended_offer_id: recommendationType === MultiLeadRecommendationType.OFFER ? recommendationId : null,
        recommended_vendor_id: recommendationType === MultiLeadRecommendationType.VENDOR ? recommendationId : null
    };

    const eventName = multileadVersion === MultileadVersion.RP_LIST_VENDOR ? "fixly_show" : "multilead_show";
    hitAlgolytics(eventName, payload);
};
