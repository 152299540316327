import React, {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {css, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button";
import {calculateRemSize, flex, mt, onDesktop, p, w100} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image";
import {Highlight, Text} from "@pg-design/text";
import {rpAppLink} from "@pg-mono/rp-routes";
import {pluralize, priceM2Format} from "@pg-mono/string-utils";

import {IRPStore} from "../../app/rp_reducer";
import {IRegionAncestor} from "../../offer/list/actions/offer_list_selected_data_actions";
import {IMarketplaceArchitectOfferSummary} from "../api/fetch_marketplace_architect_promoted_offers_by_region";
import {postArchitectApplication} from "../api/post_architect_application";
import {architectServiceTexts} from "../constants/architect_service_texts";
import {architectModalAnalytics, ArchitectModalGTMEventAction} from "../tracking/architect_modal_tracking";
import {ArchitectInfoGraphics} from "./parts/ArchitectInfoGraphics";
import {ArchitectInfoList} from "./parts/ArchitectInfoList";

interface IProps {
    architectOfferDetails: IMarketplaceArchitectOfferSummary | null;
    architectRegion: IRegionAncestor | null;
    storeHash: string;
    onArchitectOfferApplied: (architectOfferDetails: IMarketplaceArchitectOfferSummary, architectRegion: IRegionAncestor | null) => void;
    userData: {
        name?: string;
        email?: string;
        phone?: string;
    };
}

const packagesPluralize = pluralize(["pakiet", "pakiety", "pakietów"]);

export const ArchitectOfferSummaryModalContent = (props: IProps) => {
    const architectName = props.architectOfferDetails?.name || "";
    const architectNumberOfPackages = props.architectOfferDetails?.package_count || 0;
    const description = architectNumberOfPackages > 0 ? `${architectNumberOfPackages} ${packagesPluralize(architectNumberOfPackages)} wykończenia wnętrz` : "";
    const minPrice = Number(props.architectOfferDetails?.min_price_m2);
    const marketplaceLink = props.architectRegion?.slug ? rpAppLink.architect.city({regionSlug: props.architectRegion?.slug}) : rpAppLink.architect.list();

    const [error, setError] = useState("");
    const userData = props.userData;
    const data = useSelector((store: IRPStore) => store.application[props.storeHash]);
    const theme = useTheme();

    useEffect(() => {
        architectModalAnalytics.gtm.architectModalEvent(ArchitectModalGTMEventAction.SHOW_OFFER);
    }, []);

    const onArchitectOfferApplicationButtonClick = async () => {
        setError("");
        if (props.architectOfferDetails?.id && userData?.name && userData?.email && userData?.phone) {
            architectModalAnalytics.gtm.architectModalEvent(ArchitectModalGTMEventAction.OFFER_ASK);
            const result = await postArchitectApplication({
                architect: props.architectOfferDetails?.id,
                name: userData.name,
                email: userData.email,
                phone: userData.phone,
                area: data?.property?.detail?.area?.toString(),
                offer_id: data?.offer?.detail?.id,
                property_id: data?.property?.detail?.id
            });

            if (result) {
                props.onArchitectOfferApplied(props.architectOfferDetails, props.architectRegion);
                return;
            }
        }

        setError("Wystąpił problem przy próbie wysłanie formularza.");
    };

    const onMarketplaceLinkClick = () => {
        architectModalAnalytics.gtm.architectModalEvent(ArchitectModalGTMEventAction.GO_TO_OTHER_OFFERS);
    };

    const architectServiceList = useMemo(() => {
        return Object.entries(architectServiceTexts).reduce((acc, [serviceKey, serviceDescription]) => {
            if (props.architectOfferDetails?.offer?.[serviceKey as keyof IMarketplaceArchitectOfferSummary["offer"]]) {
                acc.push(serviceDescription);
            }
            return acc;
        }, [] as string[]);
    }, [props.architectOfferDetails]);

    return (
        <div css={mainWrapperStyle}>
            <div css={infoGraphicColumnStyle}>
                <Image alt={`${architectName} portfolio`} src={props.architectOfferDetails?.cover_photo} width="100%" height="100%" css={[coverImgStyle]} />
                <Image alt={`${architectName} logo`} src={props.architectOfferDetails?.logo} css={[logoStyle]} width="auto" height="90px" />

                <ArchitectInfoGraphics architectOfferDetails={props.architectOfferDetails} css={infoGraphicsWrapperStyle} />
            </div>
            <div css={textColumnStyle}>
                <Text variant="headline_2">{architectName}</Text>
                <Text variant="body_copy_1">{description}</Text>
                {minPrice > 0 && (
                    <Text variant="headline_5" css={[mt(2)]}>
                        <Highlight>od {priceM2Format(minPrice)}</Highlight>
                    </Text>
                )}

                <ArchitectInfoList texts={architectServiceList} css={[mt(5)]} />

                <div css={[mt(5)]}>
                    <Button variant="filled_primary" onClick={onArchitectOfferApplicationButtonClick} css={[w100]}>
                        Zapytaj o ofertę
                    </Button>

                    {error ? (
                        <Text variant="info_txt_1" color={theme.colors.danger} align="center" css={[mt(1)]}>
                            {error}
                        </Text>
                    ) : null}

                    <Text variant="info_txt_2" color={theme.colors.gray[700]} align="center" css={[mt(1)]}>
                        Administratorem danych jest {architectName}. Dowiedz się więcej o przetwarzaniu Twoich danych osobowych.
                    </Text>

                    <Button onClick={onMarketplaceLinkClick} variant="none_secondary" href={marketplaceLink} target="_blank" css={[mt(4), w100]}>
                        Zobacz inne oferty wykończenia
                    </Button>
                </div>
            </div>
        </div>
    );
};

const mainWrapperStyle = css`
    ${flex()};
    flex-direction: column;
    overflow: hidden;

    ${onDesktop(css`
        flex-direction: row-reverse;
    `)};
`;

const infoGraphicColumnStyle = css`
    position: relative;
    width: 100%;
    height: 42rem;

    ${onDesktop(css`
        width: 50%;
        height: auto;
        flex: 0 0 50%;
    `)};
`;

const coverImgStyle = css`
    width: 100%;
    height: 100%;

    & > img {
        object-fit: cover;
    }
`;

const logoStyle = css`
    position: absolute;
    height: 90px;
    width: auto;
    top: ${calculateRemSize(4)};
    left: 50%;
    transform: translate(-50%, 0);
`;

const infoGraphicsWrapperStyle = css`
    position: absolute;
    bottom: ${calculateRemSize(3)};
    right: ${calculateRemSize(1.5)};
    left: ${calculateRemSize(1.5)};

    ${onDesktop(css`
        right: ${calculateRemSize(4)};
        left: ${calculateRemSize(4)};
    `)};
`;

const textColumnStyle = css`
    ${p(3, 1.5)};

    ${onDesktop(css`
        ${p(3)};
        flex: 0 0 50%;
    `)};
`;
