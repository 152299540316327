import {prefixPath} from "@pg-mono/prefix-path";

export const echoApiPath = prefixPath("/api/", {
    applications: prefixPath("applications/", {
        create: prefixPath("create/", {
            base: ""
        })
    })
});

export const echoApiLink = {
    applications: {
        create: echoApiPath.applications.create.base
    }
};
