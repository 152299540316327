import React, {Fragment} from "react";
import {css, Theme} from "@emotion/react";

import {CenterBox} from "@pg-design/grid";
import {Loader} from "@pg-design/loader";

interface IProps {
    isLoading: boolean;
    offer: {
        main_image: {
            m_img_350x648: string;
        };
        name: string;
    } | null;
    vendor: {
        logo: {
            v_log_120x90: string;
        };
        name: string;
    } | null;
}

export const ExternalApiApplicationSide = (props: IProps) => {
    return (
        <div css={applicationSideWrapper}>
            {props.isLoading ? (
                <CenterBox>
                    <Loader size="md" />
                </CenterBox>
            ) : (
                <Fragment>
                    <div css={imgHolder}>
                        {props.offer && props.offer.main_image.m_img_350x648 ? (
                            <img width={350} height={648} alt={props.offer.name} src={props.offer.main_image.m_img_350x648} />
                        ) : (
                            <div css={imgEmpty} />
                        )}
                    </div>

                    {props.vendor && (
                        <div css={imgLogo}>
                            <img width={120} height={90} alt={`${props.vendor.name} logo`} src={props.vendor.logo.v_log_120x90} />
                        </div>
                    )}
                </Fragment>
            )}
        </div>
    );
};

const modalPanelWidth = "35rem";

const applicationSideWrapper = (theme: Theme) => css`
    display: none;
    position: relative;
    width: ${modalPanelWidth};

    @media (min-width: ${theme.breakpoints.md}) {
        display: flex;
    }
`;

const imgHolder = css`
    width: 100%;
    min-height: 60rem;
`;

const imgEmpty = css`
    background-image: url(${require("../../images/family.png")});
    height: 100%;
    width: 35rem;
    background-repeat: no-repeat;
    background-size: cover;
`;

const imgLogo = css`
    position: absolute;
    top: 0;
    right: 50%;
    transform: translate(50%, 0%);
    background: #fff;
    padding: 1rem;
`;
