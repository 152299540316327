import {areaFormat, pluralize} from "@pg-mono/string-utils";

import {IMapOffer} from "../../../maps/types/IMapOffer";
import {Country} from "../../../region/types/Country";
import {IMultiLeadData} from "../../types/IMultiLeadData";

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

export const transformMultiLeadOfferIntoMapOffer = (offer: IMultiLeadData): IMapOffer => ({
    id: Number(offer.id),
    name: offer.offerName,
    price: offer.price,
    coordinates: offer.coordinates,
    vendorName: offer.offerVendorName,
    vendorLogo: offer.offerVendorLogo,
    description: `${offer.rooms ? `${offer.rooms} ${roomsPluralize(offer.rooms)}, ` : ""}${offer.area ? areaFormat(offer.area) : ""}`,
    mainImage: offer.mainImage,
    distance: offer.distance,
    region: {
        country: offer.region.country || Country.POLAND
    }
});
