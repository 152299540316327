import {css, Theme} from "@emotion/react";
export const verticalFadeOut = (top: boolean, bottom: boolean) => (theme: Theme) => css`
    mask-image: linear-gradient(
        to bottom,
        transparent 0,
        ${theme.colors.gray[100]} ${top ? "10rem" : "0px"},
        ${theme.colors.gray[100]} calc(100% - ${bottom ? "10rem" : "0px"}),
        transparent 100%
    );
    transition: mask-image 200ms;
`;
