import React from "react";
import {useSelector} from "react-redux";

import {FormikForm} from "@pg-design/formik-utils";
import {mb} from "@pg-design/helpers-css";

import {IRPStore} from "../../../../app/rp_reducer";
import {IEmailVerificationProps} from "../../../hooks/use_email_verification";
import {EmailVerificationPopover} from "../../EmailVerificationPopover";

interface IProps {
    emailNotificationProps: IEmailVerificationProps;
}

export const ApplicationUserDataFormFields = (props: IProps) => {
    const countryCodes = useSelector((store: IRPStore) => store.variables.data?.country_codes ?? []);

    return (
        <>
            <FormikForm.Input name="name" placeholder="Imię i nazwisko" css={mb()} />
            <EmailVerificationPopover {...props.emailNotificationProps} popoverPlace="bottom" zIndex={10000}>
                <FormikForm.Input name="email" placeholder="Email" css={mb()} />
            </EmailVerificationPopover>
            <FormikForm.PhoneField codes={countryCodes} name="phone" placeholder="Telefon" css={mb()} className="bt" />
        </>
    );
};
