import {IRecommendedOffer} from "../../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../../recommendations/types/IRecommendedProperty";
import {IMultiLeadData} from "../../types/IMultiLeadData";

export const transformRecommendedPropertyIntoMultiLeadData = (offer: IRecommendedProperty, overrideData: Partial<IMultiLeadData> = {}): IMultiLeadData => ({
    id: offer.id,
    offerType: offer.offer.type,
    offerName: offer.offer.name,
    offerAddress: offer.offer.address,
    offerVendorName: offer.offer.vendor.name,
    offerPrivacyDataProcessorName: offer.offer.configuration.personal_data_processor_name,
    offerPrivacyPolicyUrl: offer.offer.configuration.privacy_policy_url,
    offerPersonalDataProcessorUrl: offer.offer.configuration.personal_data_processor_url,
    offerVendorLogo: offer.offer.vendor.logo.v_log_80x60,
    offerDistanceFromTheAirport: offer.offer.distance_from_the_airport,
    offerDistanceFromTheBeach: offer.offer.distance_from_the_beach,
    coordinates: offer.offer.geo_point.coordinates,
    price: offer.price,
    rooms: offer.rooms,
    area: offer.area,
    constructionDateRange: offer.offer.construction_date_range,
    mainImage: {
        desktop: offer.offer.main_image?.m_img_224x147,
        mobile: offer.offer.main_image?.m_img_155x87
    },
    distance: offer.distance,
    region: {
        country: offer.region.country
    },
    ...overrideData
});

export const transformRecommendedOfferIntoMultiLeadData = (offer: IRecommendedOffer, overrideData: Partial<IMultiLeadData> = {}): IMultiLeadData => ({
    id: offer.id,
    offerType: offer.type,
    offerName: offer.name,
    offerAddress: offer.address,
    offerVendorName: offer.vendor.name,
    offerPrivacyDataProcessorName: offer.configuration.personal_data_processor_name,
    offerPrivacyPolicyUrl: offer.configuration.privacy_policy_url,
    offerPersonalDataProcessorUrl: offer.configuration.personal_data_processor_url,
    offerVendorLogo: offer.vendor.logo.v_log_80x60,
    offerDistanceFromTheAirport: offer.distance_from_the_airport,
    offerDistanceFromTheBeach: offer.distance_from_the_beach,
    coordinates: offer.geo_point.coordinates,
    mainImage: {
        desktop: offer.main_image?.m_img_277x160,
        mobile: offer.main_image?.m_img_155x87
    },
    distance: offer.distance,
    region: {
        country: offer.region.country
    },
    ...overrideData
});
