import {initialEmptyApplicationValues} from "../../application/constants/application_form";
import {IApplicationFormValues} from "../../application/types/IApplicationFormValues";
import {getParsedString} from "../../form/utils/get_parsed_string";
import {getPhoneDataFromString} from "../../form/utils/get_phone_data_from_string";
import {IUserProfile} from "../../user/ts/interfaces/IUserProfile";
import {getLeadFormValuesFromStorage} from "./get_lead_form_values_from_storage";
import {isPhoneEmpty} from "./is_phone_empty";
import {ISentLeadStoredValues} from "./sent_lead_form_values";

export function getLeadFormValuesFromProfile(
    profileData: IUserProfile,
    defaultLeadFormValues: IApplicationFormValues,
    sentLeadFormValues: ISentLeadStoredValues | null
) {
    const profileValuesToBeRestored: (keyof IUserProfile)[] = [
        "name",
        "email",
        "phone",
        "financing_services",
        "email_marketing_consent",
        "phone_marketing_consent"
    ];

    const formValues = profileValuesToBeRestored.reduce<IApplicationFormValues>(
        (previousValue, currentValue) => {
            if (currentValue === "phone") {
                const profilePhone = profileData[currentValue];

                return {
                    ...previousValue,
                    [currentValue]: profilePhone ? getPhoneDataFromString(profilePhone) : initialEmptyApplicationValues.phone
                };
            }

            return {
                ...previousValue,
                [currentValue]: profileData[currentValue]
            };
        },
        {...defaultLeadFormValues}
    );

    const formValuesFromStorage = sentLeadFormValues && getLeadFormValuesFromStorage(sentLeadFormValues, initialEmptyApplicationValues);

    const noProfileEmail = !profileData.email || profileData.email === "";
    const isStorageVsProfileEmailDifferent = !!formValuesFromStorage && profileData.email !== formValuesFromStorage.email && formValuesFromStorage.email !== "";

    /**
     * TODO: Since we're about to "lock" and "require" email in future, shouldn't we remove this condition?
     *  This should be decided not only based on logic but also based on actual data in DB.
     *  BE team should tell if there are any users with missing emails (null/"")
     */
    if (!!formValuesFromStorage && noProfileEmail && isStorageVsProfileEmailDifferent) {
        formValues.email = formValuesFromStorage.email;
    }

    /**
     * For logged-in user show new_property_notification_consent field only when both email and phone consents are falsy.
     * For anonymous user always show new_property_notification_consent field.
     */
    formValues.new_property_notification_consent = profileData.email_marketing_consent || profileData.phone_marketing_consent;

    if (noProfileEmail) {
        formValues.new_property_notification_consent = false;
    }

    //  In case there are some values missing in profile, use the ones from localStorage (if available)
    if (!!formValuesFromStorage && (!formValues.name || formValues.name === "")) {
        formValues.name = formValuesFromStorage.name;
    }

    if (!!formValuesFromStorage && isPhoneEmpty(formValues.phone)) {
        formValues.phone = formValuesFromStorage.phone;
    }

    formValues.name = getParsedString(formValues.name, defaultLeadFormValues.name);
    formValues.email = getParsedString(formValues.email, defaultLeadFormValues.email);
    formValues.phone = formValues.phone ? formValues.phone : defaultLeadFormValues.phone;

    return formValues;
}
