import {useEffect, useState} from "react";

import {clientRpRequestMeta} from "../../../client/client_rp_request_meta";
import {IRegionAncestor} from "../../offer/list/actions/offer_list_selected_data_actions";
import {fetchMarketplaceArchitectPromotedOffersByRegion, IMarketplaceArchitectOfferSummary} from "../api/fetch_marketplace_architect_promoted_offers_by_region";
import {useRegionByOfferOrPropertyId} from "./use_region_by_offer_or_property_id";

interface IProps {
    offerId?: number;
    propertyId?: number;
}

export const useMarketplaceArchitectOfferForRegion = (props: IProps) => {
    const [architectOfferSummary, setArchitectOfferSummary] = useState<IMarketplaceArchitectOfferSummary | null>(null);
    const [isArchitectOfferLoading, setIsArchitectOfferLoading] = useState(false);
    const [architectRegion, setArchitectRegion] = useState<IRegionAncestor | null>(null);
    const originalRegion = useRegionByOfferOrPropertyId(props);

    useEffect(() => {
        const regionTypeCity = originalRegion?.stats.region_type_city;

        if (regionTypeCity) {
            setArchitectRegion(regionTypeCity);
            setIsArchitectOfferLoading(true);

            fetchMarketplaceArchitectPromotedOffersByRegion(clientRpRequestMeta, regionTypeCity?.id).then((offers) => {
                setArchitectOfferSummary(offers[0] || null);
                setIsArchitectOfferLoading(false);
            });
        }
    }, [originalRegion]);

    return {
        architectOfferSummary,
        isArchitectOfferLoading,
        architectRegion
    };
};
