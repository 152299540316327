import {Country} from "../../region/types/Country";

export enum MultiLeadStatus {
    PENDING = "pending",
    SENT = "sent",
    REJECTED = "rejected"
}

export enum MultiLeadPropertyStatus {
    UNAVAILABLE = "unavailable",
    FOR_SALE = "for_sale",
    RESERVED = "reserved"
}

export interface IMultiLeadData {
    id: string | number;
    offerType: number;
    offerName: string;
    offerVendorName: string;
    offerAddress: string;
    offerVendorLogo?: string;
    offerPrivacyDataProcessorName: string | null;
    offerPrivacyPolicyUrl: string | null;
    offerPersonalDataProcessorUrl?: string | null;
    offerDistanceFromTheAirport?: number | null;
    offerDistanceFromTheBeach?: number | null;
    coordinates: [number, number];
    price?: number | null;
    isPriceRevealed?: boolean;
    rooms?: number;
    area?: number;
    compareAreaTo?: number;
    constructionDateRange?: {upper: string};
    mainImage: {
        desktop?: string;
        mobile?: string;
    };
    distance: number;
    region: {
        country?: Country;
    };
    propertyStatus?: MultiLeadPropertyStatus;
    isSelected?: boolean;
    status?: MultiLeadStatus;
}
