import {hitGoogleTagManager} from "./init_google_tag_manager";

interface GAEvent {
    event: "GAEvent";
    GAEventCategory: "modal";
    GAEventAction: IModalType;
    GAEventLabel: IEventType;
    GAEventNoInteraction: true;
}
type IModalType = "zgoda marketingowa" | "ankieta" | "rekomendacje";
type IEventType = "wyswietlenie" | "klikniecie";

export const gtmEventPostApplicationModal = (modalType: IModalType, eventType: IEventType) => {
    const gtmData: GAEvent = {
        event: "GAEvent",
        GAEventCategory: "modal",
        GAEventAction: modalType,
        GAEventLabel: eventType,
        GAEventNoInteraction: true
    };
    hitGoogleTagManager(gtmData);
};
