import {IMarketplaceArchitectOfferSummary} from "../../architect_application/api/fetch_marketplace_architect_promoted_offers_by_region";
import {isLeadWithPriceConfirmation} from "../../lead/utils/is_lead_with_price_confirmation";
import {IRegionAncestor} from "../../offer/list/actions/offer_list_selected_data_actions";
import {IRecommendedOffer} from "../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";
import {IApplication} from "../types/IApplication";
import {IModalStepState} from "../types/IModalStepState";
import {IMultileadSummaryStepState} from "../types/IMultileadSummaryStepState";
import {LeadModalStep} from "../types/LeadModalStep";
import {IApplicationData} from "./application_reducer";

export enum ModalStepActionTypes {
    NEXT_STEP = "nextStep",
    CLOSE = "close",
    SET_IS_AUTORESPONDER_ENABLED = "setIsAutoresponderEnabled",
    OPEN = "open",
    UPDATE_LEAD_DATA = "updateLeadData",
    SINGLE_LEAD_SUMMARY_STEP = "singleLeadSummaryStep"
}

export interface IApplicationSentPayload {
    originalApplicationResponse?: IApplication | null;
    recommendedOffers?: IRecommendedProperty[] | IRecommendedOffer[] | null;
    recommendationsWithPriceFilter: boolean;
    sameOfferRecommendedProperties: boolean;
    crossPromoData?: ICrossPromoData | null;
}

interface IRecommendationsSendPayload {
    originalApplicationResponse?: IApplication | null;
    appliedRecommendedOffers?: number[];
}

interface IIsAutoresponderEnabledPayload {
    isAutoresponderEnabled: boolean;
}

interface IForceClosePayload {
    forceClose: boolean;
}

interface IShowPMOfferPayload {
    showPmOffer: boolean;
}

interface IArchitectOfferDetailsPayload {
    architectOfferDetails: IMarketplaceArchitectOfferSummary;
    architectRegion: IRegionAncestor | null;
}

type IPayload =
    | IApplicationSentPayload
    | IRecommendationsSendPayload
    | IIsAutoresponderEnabledPayload
    | IForceClosePayload
    | IArchitectOfferDetailsPayload
    | IUpdateLeadDataAction
    | IShowPMOfferPayload
    | IMultileadSummaryStepState
    | undefined;

interface INextStepAction {
    type: ModalStepActionTypes.NEXT_STEP;
    payload?: IPayload;
}

interface IOpenAction {
    type: ModalStepActionTypes.OPEN;
}

interface ICloseAction {
    type: ModalStepActionTypes.CLOSE;
    payload: IForceClosePayload;
}

interface ISetIsAutoresponderEnabledAction {
    type: ModalStepActionTypes.SET_IS_AUTORESPONDER_ENABLED;
    payload: IIsAutoresponderEnabledPayload;
}

interface IUpdateLeadDataAction {
    type: ModalStepActionTypes.UPDATE_LEAD_DATA;
    payload: IApplicationData;
}

interface ISetSingleLeadSummaryStepAction {
    type: ModalStepActionTypes.SINGLE_LEAD_SUMMARY_STEP;
    payload: {
        originalApplicationResponse: IApplication | null;
    };
}

export const initialStepState: IModalStepState = {
    step: LeadModalStep.APPLICATION_FORM,
    originalApplicationResponse: null,
    recommendedOffers: null,
    appliedRecommendedOffers: [],
    multiLeadSummaryItems: [],
    multiLeadProperty: null,
    isClosing: false,
    shouldCloseOnOverlayClick: true,
    isAutoresponderEnabled: false,
    crossPromoData: {
        getHomeOffersCount: 0,
        geoPoint: "",
        locationName: ""
    },
    recommendationsWithPriceFilter: false,
    sameOfferRecommendedProperties: false,
    architectOfferDetails: null,
    architectRegion: null,
    leadData: null
};

const CLOSING_STATE = {
    ...initialStepState,
    step: LeadModalStep.CLOSE
};

export interface ICrossPromoData {
    getHomeOffersCount: number;
    geoPoint: string;
    roomsNumber?: number[];
    locationName: string;
    isProperty?: boolean;
}

type IModalStepAction =
    | INextStepAction
    | ICloseAction
    | ISetIsAutoresponderEnabledAction
    | IOpenAction
    | IUpdateLeadDataAction
    | ISetSingleLeadSummaryStepAction;

export const modalStepReducer = (state: IModalStepState, action: IModalStepAction): IModalStepState => {
    switch (action.type) {
        case ModalStepActionTypes.NEXT_STEP: {
            return nextAfterStep(state, action.payload);
        }
        case ModalStepActionTypes.OPEN: {
            return {...state, step: LeadModalStep.APPLICATION_FORM};
        }
        case ModalStepActionTypes.CLOSE: {
            return closeAfterStep({...state, forceClose: action.payload.forceClose});
        }
        case ModalStepActionTypes.SET_IS_AUTORESPONDER_ENABLED: {
            return {
                ...state,
                isAutoresponderEnabled: action.payload.isAutoresponderEnabled
            };
        }
        case ModalStepActionTypes.UPDATE_LEAD_DATA: {
            return {
                ...state,
                leadData: action.payload
            };
        }
        case ModalStepActionTypes.SINGLE_LEAD_SUMMARY_STEP: {
            return {
                ...state,
                step: LeadModalStep.SINGLE_LEAD_SUMMARY,
                originalApplicationResponse: action.payload.originalApplicationResponse
            };
        }
        default:
            return CLOSING_STATE;
    }
};

const nextAfterStep = (state: IModalStepState, payload: IPayload) => {
    switch (state.step) {
        case LeadModalStep.APPLICATION_FORM: {
            return getAfterApplicationStep(state, payload);
        }

        case LeadModalStep.RECOMMENDED_OFFERS: {
            if (payload && "multiLeadSummaryItems" in payload && payload.multiLeadSummaryItems.length) {
                return {
                    ...state,
                    multiLeadSummaryItems: payload.multiLeadSummaryItems,
                    step: LeadModalStep.MULTILEAD_SUMMARY
                };
            }

            if (payload && "originalApplicationResponse" in payload && !payload.originalApplicationResponse?.new_property_notification_consent) {
                return {
                    ...state,
                    originalApplicationResponse: payload.originalApplicationResponse as IApplication,
                    step: LeadModalStep.NOTIFICATION_CONSENT
                };
            }

            return CLOSING_STATE;
        }

        case LeadModalStep.MULTILEAD_SUMMARY: {
            if (payload && "architectOfferDetails" in payload) {
                return {
                    ...state,
                    architectOfferDetails: payload.architectOfferDetails,
                    architectRegion: payload?.architectRegion,
                    step: LeadModalStep.ARCHITECT_OFFER
                };
            }

            if (payload && "showPmOffer" in payload && payload.showPmOffer) {
                return {
                    ...state,
                    step: LeadModalStep.PM_OFFER
                };
            }

            return CLOSING_STATE;
        }

        case LeadModalStep.SINGLE_LEAD_SUMMARY: {
            if (payload && "architectOfferDetails" in payload) {
                return {
                    ...state,
                    architectOfferDetails: payload.architectOfferDetails,
                    architectRegion: payload?.architectRegion,
                    step: LeadModalStep.ARCHITECT_OFFER
                };
            }

            return CLOSING_STATE;
        }

        case LeadModalStep.ARCHITECT_OFFER: {
            if (payload && "architectOfferDetails" in payload) {
                return {
                    ...state,
                    architectOfferDetails: payload.architectOfferDetails,
                    architectRegion: payload?.architectRegion,
                    step: LeadModalStep.ARCHITECT_MULTILEAD
                };
            }

            return CLOSING_STATE;
        }

        case LeadModalStep.PM_OFFER: {
            return {
                ...state,
                step: LeadModalStep.PM_APPLIED_OFFER
            };
        }

        default: {
            return CLOSING_STATE;
        }
    }
};

const closeAfterStep = (state: IModalStepState) => {
    switch (state.step) {
        case LeadModalStep.APPLICATION_FORM: {
            if (state.isAutoresponderEnabled || state.forceClose) {
                return CLOSING_STATE;
            }

            return {
                ...state,
                isClosing: true,
                step: LeadModalStep.GOODBYE_INFO
            };
        }
        case LeadModalStep.RECOMMENDED_OFFERS: {
            if (state.originalApplicationResponse) {
                return {
                    ...state,
                    isClosing: true,
                    step: LeadModalStep.SINGLE_LEAD_SUMMARY
                };
            }

            return CLOSING_STATE;
        }
        case LeadModalStep.SINGLE_LEAD_SUMMARY: {
            if (!state.originalApplicationResponse?.new_property_notification_consent) {
                return {
                    ...state,
                    step: LeadModalStep.NOTIFICATION_CONSENT
                };
            }

            return CLOSING_STATE;
        }
        case LeadModalStep.MULTILEAD_SUMMARY: {
            if (!state.originalApplicationResponse?.new_property_notification_consent) {
                return {
                    ...state,
                    step: LeadModalStep.NOTIFICATION_CONSENT
                };
            }

            return CLOSING_STATE;
        }
        default: {
            return CLOSING_STATE;
        }
    }
};

function isWithOriginalApplicationResponse(payload: IPayload): payload is IApplicationSentPayload & {originalApplicationResponse: IApplication} {
    return Boolean(payload && "originalApplicationResponse" in payload && payload.originalApplicationResponse);
}

function isWithRecommendations(
    payload: IPayload
): payload is IApplicationSentPayload & {recommendedOffers: IRecommendedProperty[] | IRecommendedOffer[]; recommendationsWithPriceFilter: boolean} {
    return Boolean(
        payload &&
            "recommendedOffers" in payload &&
            payload.recommendedOffers &&
            payload.recommendedOffers.length > 0 &&
            "recommendationsWithPriceFilter" in payload
    );
}

const getAfterApplicationStep = (state: IModalStepState, payload: IPayload) => {
    if (isWithOriginalApplicationResponse(payload) && isWithRecommendations(payload)) {
        const {originalApplicationResponse, recommendedOffers, recommendationsWithPriceFilter, sameOfferRecommendedProperties} = payload;

        return {
            ...state,
            step: LeadModalStep.RECOMMENDED_OFFERS,
            originalApplicationResponse,
            recommendedOffers,
            shouldCloseOnOverlayClick: false,
            recommendationsWithPriceFilter,
            sameOfferRecommendedProperties
        };
    }

    if (!isWithOriginalApplicationResponse(payload) && isWithRecommendations(payload) && isLeadWithPriceConfirmation(state.leadData)) {
        const {recommendedOffers, recommendationsWithPriceFilter, sameOfferRecommendedProperties} = payload;

        return {
            ...state,
            step: LeadModalStep.RECOMMENDED_OFFERS,
            originalApplicationResponse: null,
            recommendedOffers,
            shouldCloseOnOverlayClick: false,
            recommendationsWithPriceFilter,
            sameOfferRecommendedProperties
        };
    }

    if (isWithOriginalApplicationResponse(payload) && !payload.originalApplicationResponse.new_property_notification_consent) {
        const {originalApplicationResponse} = payload;

        return {
            ...state,
            step: LeadModalStep.NOTIFICATION_CONSENT,
            originalApplicationResponse
        };
    }

    if (isWithOriginalApplicationResponse(payload)) {
        const {originalApplicationResponse} = payload;

        return {
            ...state,
            step: LeadModalStep.SUCCESS_INFO,
            originalApplicationResponse
        };
    }

    return CLOSING_STATE;
};
