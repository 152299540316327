// Replacement for https://lodash.com/docs/4.17.15#debounce
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <TArgs>(callback: (...args: any[]) => any, wait: number) => {
    let timeout: NodeJS.Timeout;

    return function executedFunction(...args: TArgs[]) {
        const later = () => {
            clearTimeout(timeout);
            callback(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};
