import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button";
import {calculateRemSize as crs, listUnStyled, onDesktop, p} from "@pg-design/helpers-css";
import {CloseIcon, InfoIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";
import {useUserDevice} from "@pg-mono/user-device";

import {IRPStore} from "../../../app/rp_reducer";
import {applicationFormFields} from "../../../application/constants/application_form";
import {useTransformApplicationToMultiLead} from "../../../application/hooks/use_transform_application_to_multi_lead";
import {IApplication} from "../../../application/types/IApplication";
import {IApplicationFormValues} from "../../../application/types/IApplicationFormValues";
import {IMultileadSummaryStepState} from "../../../application/types/IMultileadSummaryStepState";
import {IOriginalApplicationProperty} from "../../../application/types/IOriginalApplicationProperty";
import {MultiLeadRecommendationType} from "../../../application/types/MultiLeadRecommendationType";
import {RecommendedPropertyApplicationsType} from "../../../application/types/recommended_property_applications_type";
import {alertFormSuccess} from "../../../application/utils/alert_text";
import {ApplicationSource, ApplicationSourceSection} from "../../../application/utils/ApplicationSource";
import {addOffersToStorageSentApplication} from "../../../application/utils/manage_storage_sent_applications";
import {addPropertiesToStorageSentApplication} from "../../../application/utils/property_application_storage";
import {report} from "../../../application/utils/recommended_application/analytics_events";
import {getGeneratedMessageSimplified} from "../../../application/utils/text_generator/get_generated_message_simplified";
import {toJsonValues} from "../../../form/translate/to_json_values";
import {addNotification} from "../../../notifications/state/notifications_slice";
import {addAppliedOffers} from "../../../offer/map/actions/add_applied_offers";
import {flatsPluralize} from "../../../real_estate/constants/flats_pluralize";
import {IRecommendedProperty} from "../../../recommendations/types/IRecommendedProperty";
import {useCreateMultipleLeadsMutation} from "../../api/create_lead";
import {hitMultileadRecommendationShown} from "../../tracking/hit_multilead_recommendation_shown";
import {hitVendorMultileadClose} from "../../tracking/hit_vendor_multilead_close";
import {hitVendorMultileadSendButton} from "../../tracking/hit_vendor_multilead_send_button";
import {hitVendorMultileadShow} from "../../tracking/hit_vendor_multilead_show";
import {MultileadVersion} from "../../types/MultileadVersion";
import {RecommendationSource} from "../../types/RecommendationSource";
import {handleMultiLeadClose} from "../../utils/handle_multilead_close";
import {IRodoVendorDetails, MultiVendorRodoText} from "../common/MultiVendorRodoText";
import {MultiLeadWrapper} from "../MultiLeadWrapper";
import {VendorMultileadGroup} from "./VendorMultileadGroup";
import {VendorMultileadMap} from "./VendorMultileadMap";

type IProps = {
    recommendations: IRecommendedProperty[];
    storeHash: string;
    originalApplicationFormValues: IApplicationFormValues;
    originalApplicationResponse: IApplication | null;
    multiLeadProperty: IOriginalApplicationProperty;
    handleSetMultiLeadSummaryStep: (payload: IMultileadSummaryStepState) => void;
    handleSetSingleLeadSummaryStep: (originalApplicationResponse: IApplication | null) => void;
    handleModalClose: () => void;
};

export function VendorMultilead(props: IProps) {
    const {
        // leadOffer,
        recommendations,
        storeHash,
        originalApplicationFormValues,
        originalApplicationResponse,
        multiLeadProperty,
        handleSetMultiLeadSummaryStep,
        handleSetSingleLeadSummaryStep,
        handleModalClose
    } = props;

    const vendorRecommendations = useMemo(() => groupByVendorId(recommendations), [recommendations]);
    const recommendedProperties = useMemo(() => vendorRecommendations.map((vendorGroup) => vendorGroup.map((rec) => rec)).flat(), [vendorRecommendations]);
    const recommendationsAmount = recommendedProperties.length;

    const {isMobile} = useUserDevice();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((store: IRPStore) => store.isAuthenticated);
    const viewType = useSelector((store: IRPStore) => store.viewType.current);
    const [createMultipleLeads, {isSuccess, isLoading, isError, data}] = useCreateMultipleLeadsMutation();
    const createdLeads = isError ? [] : data || [];
    const originalApplication = useSelector((store: IRPStore) => store.application[storeHash]);
    const {applications: allCreatedLeads} = useTransformApplicationToMultiLead({
        storeHash,
        originalApplicationResponse,
        originalApplication,
        multiLeadProperty,
        additionalLeads: createdLeads.map((lead) => ({
            application: lead,
            recommendedProperty: recommendedProperties.find((property) => property.id === lead.property)
        }))
    });

    const leadOffer = originalApplication?.offer?.detail;
    const multileadSourceSection = ApplicationSourceSection.VENDOR_MULTILEAD;

    useEffect(() => {
        hitVendorMultileadShow(recommendationsAmount);

        for (const recommendation of recommendedProperties) {
            hitMultileadRecommendationShown({
                recommendationType: MultiLeadRecommendationType.VENDOR,
                recommendationId: recommendation.id,
                viewType,
                recommendationSource: RecommendationSource.DISTANCE,
                multileadVersion: MultileadVersion.RP_LIST_VENDOR
            });
        }
    }, [recommendedProperties]);

    useEffect(() => {
        // TODO Change to success only scenario after BE "deadlock" is fixed - https://app.clickup.com/t/862k002n6
        if (isSuccess) {
            dispatch(addNotification(alertFormSuccess));

            addOffersToStorageSentApplication(
                recommendedProperties.map((recommendedProperty) => {
                    return {
                        id: recommendedProperty.offer.id,
                        coordinates: recommendedProperty.offer.geo_point.coordinates,
                        country: recommendedProperty.region.country,
                        regionId: recommendedProperty.region.parent?.id
                    };
                })
            );

            addPropertiesToStorageSentApplication(recommendedProperties.map((property) => property.id));

            if (isAuthenticated) {
                const offerIds = createdLeads.map((lead) => lead.offer);
                dispatch(addAppliedOffers(offerIds));
            }

            for (const lead of createdLeads) {
                report.singleApplicationSuccess(lead, multileadSourceSection, viewType);
            }

            const sentLeads = createdLeads.reduce<RecommendedPropertyApplicationsType[]>((acc, lead) => {
                const recommendedProperty = recommendedProperties.find(({id}) => id === lead.property);
                if (recommendedProperty) {
                    acc.push([lead, recommendedProperty]);
                }
                return acc;
            }, []);

            report.finishedSending(MultiLeadRecommendationType.VENDOR, sentLeads, viewType, originalApplicationResponse, false, "Fixly");

            handleSetMultiLeadSummaryStep({
                multiLeadSummaryItems: allCreatedLeads,
                multiLeadProperty
            });

            return;
        }

        if (isError) {
            handleSetSingleLeadSummaryStep(originalApplicationResponse);
        }
    }, [createdLeads]);

    const handleSendRecommendations = () => {
        const payload: Partial<IApplication>[] = recommendedProperties.map((recommendedProperty) => {
            const text = getGeneratedMessageSimplified({
                offerType: recommendedProperty.offer.type,
                offerName: recommendedProperty.offer.name,
                propertyNumber: recommendedProperty.number,
                name: originalApplicationFormValues.name,
                rooms: recommendedProperty.rooms,
                area: recommendedProperty.area
            });

            return {
                referer_application_uuid: originalApplicationResponse?.referer_application_uuid,
                source: ApplicationSource.RecommendedProperty,
                source_section: multileadSourceSection,
                property: recommendedProperty.id,
                ...toJsonValues(applicationFormFields, originalApplicationFormValues),
                text
            };
        });

        createMultipleLeads(payload);

        hitVendorMultileadSendButton(recommendationsAmount);
    };

    const onCloseClick = () => {
        hitVendorMultileadClose(recommendationsAmount);

        handleMultiLeadClose({
            originalApplicationResponse,
            sentLeads: allCreatedLeads,
            multiLeadProperty,
            handleSetMultiLeadSummaryStep: handleSetMultiLeadSummaryStep,
            handleSetAfterSummaryStep: handleSetSingleLeadSummaryStep,
            handleModalClose: handleModalClose
        });
    };

    const vendorsRodoDetails: IRodoVendorDetails[] = useMemo(
        () =>
            vendorRecommendations.map((vendorGroup) => ({
                vendorName: vendorGroup[0].offer.vendor.name
            })),
        [vendorRecommendations]
    );

    return (
        <MultiLeadWrapper isScrollable>
            <>
                {leadOffer && (
                    <div css={vendorMultilead}>
                        <Button variant="none_secondary" iconLeft={CloseIcon} css={closeButton} onClick={onCloseClick} />
                        <div css={listWrap}>
                            <div css={listContent}>
                                <div css={introText}>
                                    <Text variant="headline_4" css={headerText}>
                                        Zapytaj o dodatkowe, specjalne oferty
                                    </Text>
                                    <Text variant="body_copy_2">
                                        Po analizie Twoich wyborów i poszukiwań, znaleźliśmy u deweloperów jeszcze{" "}
                                        <strong>
                                            {recommendationsAmount} {flatsPluralize(recommendationsAmount)} o podobnych parametrach i układzie funkcjonalnym
                                        </strong>{" "}
                                        w najbliższej okolicy.
                                    </Text>
                                </div>
                                <div css={vendorsListWrap}>
                                    <ul css={groupList}>
                                        {vendorRecommendations.map((vendorGroup, i) => {
                                            const {id: vendorId} = vendorGroup[0].offer.vendor;

                                            return (
                                                <li key={vendorId}>
                                                    <VendorMultileadGroup recommendationsFitting={getRecommendationsFitting(i)} vendorGroup={vendorGroup} />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <div css={vendorListDescription}>
                                        <InfoIcon size="1.6" wrapperSize="1.6" wrapperColor="transparent" fill={theme.colors.gray[800]} />
                                        <Text variant="info_txt_1" color={theme.colors.gray[800]}>
                                            Twoje zapytanie priorytetowo trafi bezpośrednio do opiekunów inwestycji, więc kontakt nastąpi szybko (przeważnie do
                                            24 godzin).
                                        </Text>
                                    </div>
                                </div>
                            </div>
                            {isMobile && (
                                <div css={mapWrapMobile}>
                                    <VendorMultileadMap
                                        center={leadOffer.geo_point.coordinates}
                                        mapRecommendations={recommendedProperties.map((recommendation) => ({
                                            logoImg: recommendation.offer.vendor.logo.v_log_80x60,
                                            alt: recommendation.offer.vendor.name,
                                            geoPoint: recommendation.offer.geo_point.coordinates
                                        }))}
                                    />
                                </div>
                            )}
                            <div css={submitContent}>
                                <Button
                                    variant="filled_primary"
                                    disabled={isLoading || isSuccess || isError}
                                    isLoading={isLoading}
                                    onClick={handleSendRecommendations}
                                >
                                    Wyślij zapytanie
                                </Button>
                                <MultiVendorRodoText vendors={vendorsRodoDetails} align="center" />
                            </div>
                        </div>
                        {!isMobile && (
                            <div css={mapWrap}>
                                <VendorMultileadMap
                                    center={leadOffer.geo_point.coordinates}
                                    mapRecommendations={recommendedProperties.map((recommendation) => ({
                                        logoImg: recommendation.offer.vendor.logo.v_log_80x60,
                                        alt: recommendation.offer.vendor.name,
                                        geoPoint: recommendation.offer.geo_point.coordinates
                                    }))}
                                />
                            </div>
                        )}
                    </div>
                )}
            </>
        </MultiLeadWrapper>
    );
}
// Utils
function groupByVendorId(recommendations: IRecommendedProperty[]) {
    const vendorGroups = recommendations.reduce(
        (acc, recommendation) => {
            const vendorId = recommendation.offer.vendor.id;

            const vendorHasMaxOffers = acc[vendorId] && acc[vendorId].length === 3;
            if (vendorHasMaxOffers) {
                return acc;
            }

            const vendorIsMissing = !acc[vendorId];
            if (vendorIsMissing) {
                acc[vendorId] = [];
            }

            const offerIsInVendor = acc[vendorId].some((vendorRecommendation) => vendorRecommendation.offer.id === recommendation.offer.id);
            if (offerIsInVendor) {
                return acc;
            }

            const canAddOffer = acc[vendorId] && acc[vendorId].length < 3;
            if (canAddOffer) {
                acc[vendorId].push(recommendation);
            }

            return acc;
        },
        {} as Record<string, IRecommendedProperty[]>
    );

    return Object.entries(vendorGroups)
        .sort((a, b) => b[1].length - a[1].length)
        .splice(0, 3)
        .map((vendorWithGroup) => vendorWithGroup[1]);
}

function getRecommendationsFitting(vendorGroupIndex: number) {
    if (vendorGroupIndex === 0) {
        return 98;
    }

    if (vendorGroupIndex === 1) {
        return 96;
    }

    return 95;
}

// Styles
const vendorMultilead = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.colors.gray[100]};
    position: relative;

    ${onDesktop(css`
        ${p(3)};
        flex-direction: row;
        column-gap: ${crs(4)};
    `)}
`;

const listWrap = css`
    display: flex;
    flex-direction: column;
    row-gap: ${crs(4)};

    ${onDesktop(css`
        flex: 0 0 44rem;
        ${p(0)};
    `)}
`;

const listContent = css`
    ${p(3, 1.5, 0, 1.5)};
    display: flex;
    flex-direction: column;
    gap: ${crs(4)};

    ${onDesktop(css`
        ${p(0)};
    `)};
`;

const groupList = css`
    ${listUnStyled};
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: ${crs(2)};
`;

const introText = css`
    display: flex;
    flex-direction: column;
    gap: ${crs(2)};
`;

const headerText = css`
    max-width: 280px;

    ${onDesktop(css`
        max-width: unset;
    `)}
`;

const vendorsListWrap = css`
    display: flex;
    flex-direction: column;
    gap: ${crs(2)};
`;

const vendorListDescription = css`
    display: flex;
    gap: ${crs(1)};
`;

const submitContent = css`
    ${p(0, 1.5, 3, 1.5)};
    display: flex;
    flex-direction: column;
    gap: ${crs(2)};

    ${onDesktop(css`
        ${p(0)};
    `)};
`;

const mapWrap = css`
    position: relative;
    flex: 1 1 100%;
`;

const mapWrapMobile = css`
    position: relative;
    height: 336px;
`;

const closeButton = css`
    width: ${crs(4)};
    height: ${crs(4)};
    display: inline-flex;
    position: absolute;
    z-index: 2;
    inset: ${crs(3)} ${crs(3)} auto auto;
    background: white;
`;
