import {Dispatch} from "redux";

import {toJsonValues} from "../../../form/translate/to_json_values";
import {addAppliedOffers} from "../../../offer/map/actions/add_applied_offers";
import {OfferType} from "../../../offer/types/OfferType";
import {IRecommendedOffer} from "../../../recommendations/types/IRecommendedOffer";
import {ViewType} from "../../../view_type/ViewType";
import {postRecommendedApplication} from "../../actions/post_recommended_application";
import {RecommendedOfferApplicationsType} from "../../actions/track_recommended_offers_application";
import {IApplicationAdditionalQuestionTypes} from "../../components/application_form/ApplicationFormContext";
import {applicationFormFields} from "../../constants/application_form";
import {IApplication} from "../../types/IApplication";
import {IApplicationFormValues} from "../../types/IApplicationFormValues";
import {MultiLeadRecommendationType} from "../../types/MultiLeadRecommendationType";
import {ApplicationSource, ApplicationSourceSection} from "../ApplicationSource";
import {addOffersToStorageSentApplication} from "../manage_storage_sent_applications";
import {getGeneratedMessageSimplified} from "../text_generator/get_generated_message_simplified";
import {report} from "./analytics_events";

// Data used in reporting purposes both by backend and platforms like algolytics tracker and GTM
interface IPropertyMultiApplicationMetadata {
    refererApplicationUUID: string | undefined;
    viewType: ViewType | null;
    sourceSection: ApplicationSourceSection;
    isAuthenticated: boolean;
    application: IApplication | undefined | null;
    originalFormParams: {
        rooms: number[];
        questions?: IApplicationAdditionalQuestionTypes[];
    };
}

interface ISingleApplicationPayload {
    recommendedElement: {
        configuration: {
            is_autoresponder_enabled: boolean;
        };
        id: number;
        name: string;
        type: OfferType;
    };
    formValues: IApplicationFormValues;
}

const sendOfferApplication =
    (payload: ISingleApplicationPayload, metadata: IPropertyMultiApplicationMetadata) =>
    (dispatch: Dispatch): Promise<IApplication | void> => {
        const jsonValues = toJsonValues(applicationFormFields, payload.formValues);
        const source = ApplicationSource.RecommendedOffer;

        const text = getGeneratedMessageSimplified({
            offerType: payload.recommendedElement.type,
            offerName: payload.recommendedElement.name,
            name: payload.formValues.name
        });

        const applicationData: Partial<IApplication> = {
            referer_application_uuid: metadata.refererApplicationUUID,
            source,
            source_section: metadata.sourceSection,
            offer: payload.recommendedElement.id,
            ...jsonValues,
            text
        };

        return postRecommendedApplication(applicationData)(
            // pass dispatch instead of wrap because of return type
            dispatch
        );
    };

interface IPropertyMultiApplicationPayload {
    selectedRecommendations: IRecommendedOffer[];
    formValues: IApplicationFormValues;
}

export const sendMultipleOfferApplications = (payload: IPropertyMultiApplicationPayload, metadata: IPropertyMultiApplicationMetadata) => (dispatch: Dispatch) =>
    Promise.all(
        payload.selectedRecommendations.map((recommendation) =>
            sendOfferApplication(
                {
                    recommendedElement: recommendation,
                    formValues: payload.formValues
                },
                metadata
            )(
                // pass dispatch instead of wrap to properly catch errors
                dispatch
            )
        )
    ).then((response) => {
        if (!response.filter(Boolean).length) {
            return [];
        }

        const offerIDs = response.reduce<number[]>((acc, application) => {
            return application ? acc.concat(application.offer) : acc;
        }, []);

        if (metadata.isAuthenticated) {
            dispatch(addAppliedOffers(offerIDs));
        }

        addOffersToStorageSentApplication(
            payload.selectedRecommendations.map((offer) => {
                return {id: offer.id, coordinates: offer.geo_point.coordinates, country: offer.region.country, regionId: offer.region.parent?.id};
            })
        );

        const applications = response.reduce<RecommendedOfferApplicationsType[]>((acc, application) => {
            if (application) {
                const recommendedOffer = payload.selectedRecommendations.find(({id}) => id === application.offer);

                report.singleApplicationSuccess(application, metadata.sourceSection, metadata.viewType);

                if (recommendedOffer) {
                    acc.push([application, recommendedOffer]);
                }
            }
            return acc;
        }, []);

        report.finishedSending(MultiLeadRecommendationType.OFFER, applications, metadata.viewType, metadata.application);

        return applications;
    });
