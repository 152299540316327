import React, {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {css, Theme, useTheme} from "@emotion/react";
import {FormikHelpers} from "formik";
import * as Yup from "yup";

import {FormikForm} from "@pg-design/formik-utils";
import {mb, mr, mt, onDesktop} from "@pg-design/helpers-css";
import {Popover} from "@pg-design/notifications";
import {Text} from "@pg-design/text";
import {FormFieldType} from "@pg-mono/form";
import {useIsMobile} from "@pg-mono/hooks";
import {omit} from "@pg-mono/nodash";

import {IRPStore} from "../../../app/rp_reducer";
import {toJsonValues} from "../../../form/translate/to_json_values";
import {validationMessages} from "../../../form/utils/validation_messages";
import {addNotification} from "../../../notifications/state/notifications_slice";
import {externalApiApplicationSent} from "../../actions/external_api_application_sent";
import {ApplicationSource} from "../../utils/ApplicationSource";
import {generateApplicationText} from "../../utils/generate_application_text";

interface IProps {
    offer: {
        custom_form_text: string | null;
        name: string;
    } | null;
    onModalClose: () => void;
    property: {
        number: string;
    } | null;
    vendor: {
        logo: {
            v_log_80x60: string;
        };
        name: string;
    } | null;
    source: ApplicationSource;
}

const defaultInitialValues = {
    email: "",
    name: "",
    surname: "",
    phone: {code: 48, number: ""},
    consent_1: false,
    consent_2: false,
    consent_3: false,
    text: ""
};

export type EchoApplicationFormValuesType = typeof defaultInitialValues;

const validationSchema = Yup.object({
    email: Yup.string().required(validationMessages.required).email(validationMessages.email),
    name: Yup.string().required(validationMessages.required),
    surname: Yup.string().required(validationMessages.required),
    phone: Yup.object({
        code: Yup.number().required(validationMessages.required),
        number: Yup.string().required(validationMessages.required)
    }),
    consent_1: Yup.boolean().oneOf([true], validationMessages.required),
    consent_2: Yup.boolean().oneOf([true], validationMessages.required),
    consent_3: Yup.boolean().oneOf([true], validationMessages.required),
    text: Yup.string().required(validationMessages.required)
});

export const ExternalApiEchoApplicationForm = (props: IProps) => {
    const dispatch = useDispatch();

    const isMobile = useIsMobile();

    const theme = useTheme();

    const countryCodes = useSelector((state: IRPStore) => state.variables.data?.country_codes) ?? [];

    const [initialValues, setInitialValues] = useState<EchoApplicationFormValuesType>({...defaultInitialValues});

    useEffect(() => {
        const generateApplicationTextProps = {
            source: props.source,
            offerName: props.offer?.name,
            propertyNumber: props.property?.number,
            custom_form_text: props.offer?.custom_form_text
        };

        let text = generateApplicationText(generateApplicationTextProps);

        if (!text) {
            if (props.offer?.name) {
                text = `Zainteresowała mnie nieruchomość w Państwa inwestycji: ${props.offer.name}. Proszę o niezobowiązujący kontakt w sprawie szczegółów oferty.`;
            }
        }

        text && setInitialValues((prev) => ({...prev, text}));
    }, [props.source, props.offer]);

    const onSubmit = (values: EchoApplicationFormValuesType, {setSubmitting, setErrors}: FormikHelpers<EchoApplicationFormValuesType>) => {
        const data = {
            user: {
                ...omit(values, ["text"]),
                phone: toJsonValues({phone: FormFieldType.PhoneField}, values)?.phone
            },
            text: values.text,
            offer: props.offer?.name ?? "",
            url: window.location.href
        };

        externalApiApplicationSent(data)
            .then(() => {
                dispatch(addNotification({type: "success", content: "Twoje zapytanie zostało wysłane"}));

                props.onModalClose();
            })
            .catch((error: {fieldErrors: Record<string, string[]>}) => {
                dispatch(addNotification({type: "warning", content: "Twoje zapytanie nie zostało wysłane"}));

                error.fieldErrors && setErrors(error.fieldErrors);
                setSubmitting(false);
            });
    };

    const echoRodoLink = (
        <a href="https://www.echo.com.pl/rodo" target="_blank" rel="nofollow, noopener">
            www.echo.com.pl/rodo
        </a>
    );

    return (
        <div css={wrapper}>
            <div css={header}>
                {isMobile && props.vendor && <img css={mr(1)} width={56} height={42} alt={`${props.vendor.name} logo`} src={props.vendor.logo.v_log_80x60} />}

                <div css={offerInfo}>
                    {props.offer && <Text variant="headline_6">{props.offer.name}</Text>}
                    {props.vendor && (
                        <Text color={theme.colors.gray[700]} variant="body_copy_2" className="bt">
                            {props.vendor.name}
                        </Text>
                    )}
                </div>
            </div>

            <FormikForm initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit} enableReinitialize onChange={() => null}>
                <div>
                    <FormikForm.Textarea name="text" css={fieldStyle} />

                    <div css={userName}>
                        <FormikForm.Input name="name" placeholder="Imię" css={[mb(2), onDesktop(mb(0))]} />
                        <FormikForm.Input name="surname" placeholder="Nazwisko" />
                    </div>

                    <FormikForm.Input name="email" type="email" placeholder="Email" css={fieldStyle} />

                    <FormikForm.PhoneField name="phone" codes={countryCodes} placeholder="Numer telefonu" css={fieldStyle} className="bt" />

                    <Text variant="info_txt_2" mb="0.8rem">
                        {!isMobile &&
                            "Przed wyrażeniem zgód zapoznaj się z informacjami o przetwarzaniu danych. Administratorem Państwa danych osobowych jest Echo Investment."}
                    </Text>

                    <FormikForm.Checkbox
                        id="consent_1"
                        name="consent_1"
                        labelContent={
                            <Popover
                                isActive
                                popoverPlace="top"
                                toggleClickHandler
                                toggleOnClickOutside
                                body={
                                    <Fragment>
                                        Wyrażam zgodę na przetwarzanie moich danych osobowych przez Echo Investment S.A. dla celów marketingowych, tj. w celu
                                        prezentacji ofert Echo Investment S.A. oraz spółek inwestycyjnych z grupy Echo*. (* „Spółki inwestycyjne z grupy Echo”
                                        oznaczają podmioty powiązane kapitałowo z Echo Investment S.A. powołane w celu realizacji inwestycji i sprzedaży
                                        nieruchomości klientom indywidualnym. Pełna oraz aktualna lista takich podmiotów znajduje się na stronie internetowej{" "}
                                        {echoRodoLink})
                                    </Fragment>
                                }
                            >
                                <Text variant="info_txt_2">Wyrażam zgodę na przetwarzanie moich danych osobowych *</Text>
                            </Popover>
                        }
                    />

                    <FormikForm.Checkbox
                        id="consent_2"
                        name="consent_2"
                        labelContent={
                            <Popover
                                popoverPlace="top"
                                isActive
                                toggleClickHandler
                                toggleOnClickOutside
                                body={
                                    <Fragment>
                                        Wyrażam zgodę na przesyłanie mi informacji marketingowych i ofert handlowych za pomocą środków komunikacji
                                        elektronicznej zgodnie z art. 10 ustawy z dnia 18 lipca 2002 o świadczeniu usług drogą elektroniczną, w tym w
                                        szczególności na podane przeze mnie adresy email, przez Echo Investment S.A., dotyczących ofert Echo Investment S.A.
                                        oraz spółek inwestycyjnych z grupy Echo*.(* „Spółki inwestycyjne z grupy Echo” oznaczają podmioty powiązane kapitałowo z
                                        Echo Investment S.A. powołane w celu realizacji inwestycji i sprzedaży nieruchomości klientom indywidualnym. Pełna oraz
                                        aktualna lista takich podmiotów znajduje się na stronie internetowej {echoRodoLink})
                                    </Fragment>
                                }
                            >
                                <Text variant="info_txt_2">
                                    Wyrażam zgodę na przesyłanie mi informacji marketingowych i ofert handlowych za pomocą środków komunikacji elektronicznej *
                                </Text>
                            </Popover>
                        }
                    />

                    <FormikForm.Checkbox
                        id="consent_3"
                        name="consent_3"
                        labelContent={
                            <Popover
                                isActive
                                popoverPlace="top"
                                toggleClickHandler
                                toggleOnClickOutside
                                body={
                                    <Fragment>
                                        Wyrażam zgodę na prowadzenie marketingu bezpośredniego, w szczególności przedstawianie mi informacji marketingowych i
                                        ofert handlowych przy użyciu telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących zgodnie z
                                        art. 172 ust. 1 ustawy z dnia 16 lipca 2004 r. Prawo telekomunikacyjne, poprzez m.in. komunikację za pomocą telefonu i
                                        wiadomości SMS/MMS skierowaną na podane przeze mnie numery telefonów, przez Echo Investment S.A., dotyczących ofert Echo
                                        Investment S.A. oraz spółek inwestycyjnych z grupy Echo*.(* „Spółki inwestycyjne z grupy Echo” oznaczają podmioty
                                        powiązane kapitałowo z Echo Investment S.A. powołane w celu realizacji inwestycji i sprzedaży nieruchomości klientom
                                        indywidualnym. Pełna oraz aktualna lista takich podmiotów znajduje się na stronie internetowej {echoRodoLink})
                                    </Fragment>
                                }
                            >
                                <Text variant="info_txt_2">
                                    Wyrażam zgodę na prowadzenie marketingu bezpośredniego (przy użyciu urządzeń telekomunikacyjnych, w szczególności telefonu)
                                    *
                                </Text>
                            </Popover>
                        }
                    />
                </div>
                <FormikForm.Button css={submitButton} variant="filled_primary">
                    WYŚLIJ
                </FormikForm.Button>
            </FormikForm>
        </div>
    );
};

const wrapper = css`
    padding: 1.8rem 3rem 4rem 4rem;
    min-height: 100%;
    display: flex;
    flex-direction: column;
`;

const fieldStyle = css`
    ${mb(2)};
`;

const header = css`
    margin-bottom: 1.6rem;
    position: relative;
    display: flex;
    align-items: center;
`;

const offerInfo = css`
    display: flex;
    flex-direction: column;
`;

const userName = (theme: Theme) => css`
    ${mb(2)};
    display: flex;
    justify-content: space-between;

    @media (max-width: ${theme.breakpoints.md}) {
        flex-direction: column;
    }
`;

const submitButton = css`
    ${mt(2)};
    width: 100%;
`;
