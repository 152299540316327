import {echoApiLink} from "@pg-mono/external-api-routes";
import {FormFieldType} from "@pg-mono/form";
import {isEmpty} from "@pg-mono/nodash";
import {catch400, catch403, postExternalRequest} from "@pg-mono/request";

import {echoApiUrl} from "../../app/read_rp_environment_variables";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {toJsonValues} from "../../form/translate/to_json_values";

interface IEchoApplication {
    user: {
        email: string;
        consent_1: boolean;
        consent_2: boolean;
        consent_3: boolean;
        name: string;
        surname: string;
        phone: string;
    };
    text: string;
    offer: string;
    url: string;
}

const url = `${echoApiUrl}${echoApiLink.applications.create}`;
const headers = {
    authorization: "Token 3d753a44af0281940e778dd63f199bcd0a325c85"
};

export const externalApiApplicationSent = (values: IEchoApplication): Promise<unknown> => {
    const parsedValues = toJsonValues({phone: FormFieldType.PhoneField}, values);

    const data = {
        ...values,
        ...parsedValues
    };

    return postExternalRequest({}, url, data, headers)
        .then((application) => {
            return application;
        })
        .catch(
            catch400((err) => {
                if (isEmpty(err.appError?.fieldErrors)) {
                    throw err;
                }

                const fieldErrors = Object.entries(err.appError?.fieldErrors || []).reduce(
                    (acc, [key, value]) => {
                        if (Array.isArray(value)) {
                            return {...acc, [key]: value};
                        }

                        if (typeof value === "object") {
                            return {...acc, ...(value as unknown as Record<string, string[]>)};
                        }

                        return acc;
                    },
                    {} as Record<string, string[]>
                );

                return Promise.reject({fieldErrors});
            })
        )
        .catch(
            catch403((err) => {
                notifyBugsnag(err, "externalApiApplicationSent: catch 403", err.responseError ?? {});

                throw err;
            })
        );
};
