import {useState} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {MULTI_LEAD_DISTANCES_IN_KM} from "../constants/multi_lead_constants";
import {MultiLeadRecommendationType} from "../types/MultiLeadRecommendationType";

export interface IPriceFilter {
    lower?: number;
    upper?: number;
}

interface IOptions {
    recommendationType: MultiLeadRecommendationType;
    defaultPriceLimit: IPriceFilter;
}
export const useRecommendationsFilters = (options: IOptions) => {
    const recommendedProperties = useSelector((store: IRPStore) => store.property.recommendedProperties);
    const recommendedOffers = useSelector((store: IRPStore) => store.offerDetail.recommendedOffers);

    const [distanceFilterInKm, setDistanceFilterInKm] = useState(() => {
        const firstOffer = options.recommendationType === MultiLeadRecommendationType.PROPERTY ? recommendedProperties[0] : recommendedOffers[0];
        const defaultDistance = firstOffer?.distance > MULTI_LEAD_DISTANCES_IN_KM[0] ? MULTI_LEAD_DISTANCES_IN_KM[1] : MULTI_LEAD_DISTANCES_IN_KM[0];

        return defaultDistance || MULTI_LEAD_DISTANCES_IN_KM[0];
    });
    const [priceFilter, setPriceFilter] = useState<IPriceFilter>(options.defaultPriceLimit);

    return {priceFilter, setPriceFilter, distanceFilterInKm, setDistanceFilterInKm, canDistanceBeIncreased: distanceFilterInKm < MULTI_LEAD_DISTANCES_IN_KM[1]};
};
