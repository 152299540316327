import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";
import {rpAppLink} from "@pg-mono/rp-routes";

import {useResponsiveLinkTarget} from "../../../hooks/use_responsive_link_target";

export interface IRodoVendorDetails {
    vendorName: string;
}

interface IRodoTextProps {
    vendors: IRodoVendorDetails[];
    className?: string;
    textVariant?: Parameters<typeof Text>["0"]["variant"];
    align?: Parameters<typeof Text>["0"]["align"];
}

export const MultiVendorRodoText = (props: IRodoTextProps) => {
    const vendorsCount = props.vendors.length;

    const responsiveLinkTarget = useResponsiveLinkTarget();
    const theme = useTheme();
    const termsLink = rpAppLink.rodoTerms.base();

    return (
        <RodoTextBase className={props.className}>
            <Text align={props.align || "left"} color={theme.colors.gray[700]} variant={props.textVariant || "info_txt_2"} css={w100}>
                {vendorsCount > 1 ? "Administratorami Twoich danych osobowych są: " : "Administratorem Twoich danych osobowych jest "}
                {props.vendors.map((vendor, index) => (
                    <span key={vendor.vendorName}>
                        {vendor.vendorName}
                        {index < vendorsCount - 1 ? ", " : ""}
                        {index === vendorsCount - 1 && vendor.vendorName[vendor.vendorName.length - 1] !== "." ? ". " : " "}
                    </span>
                ))}
                <a css={linkStyles} href={termsLink} target={responsiveLinkTarget}>
                    Dowiedz się więcej o przetwarzaniu Twoich danych osobowych.
                </a>
            </Text>
        </RodoTextBase>
    );
};

const RodoTextBase = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;

    a,
    a:hover {
        color: inherit;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const linkStyles = css`
    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
        color: inherit;
        text-decoration: underline;
    }
`;
