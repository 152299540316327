import {getRequest} from "@pg-mono/request";
import {appendQueryString} from "@pg-mono/request";
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPRequestMeta} from "../../app/rp_request_meta";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IPropertyDetail} from "../interfaces/IPropertyDetail";

export const fetchPropertiesDetailByIds = (
    meta: IRPRequestMeta,
    propertyIds: number[],
    shouldIncludeRecommendationParameters: boolean
): Promise<IPaginatedResponse<IPropertyDetail>> => {
    const requestUrl = appendQueryString(apiV2ListLink.property.list(Scenario.PROPERTY_DETAIL), {
        include_property: propertyIds,
        ...(shouldIncludeRecommendationParameters ? {over_limit: false, skip_recommendations: false, for_recommendations: true} : {})
    });

    return getRequest(meta, requestUrl);
};
