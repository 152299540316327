import {useState} from "react";

import {fetchRegionTree, IRegion} from "../../region/fetch_region_tree";
import {RegionType} from "../../region/types/RegionType";
import {
    ApiFieldToEstimatePriceType,
    cityWithEstimatedPriceStats,
    DEFAULT_API_FIELD_TO_ESTIMATE_PRICE,
    districtsWithEstimatedPriceStats
} from "../utils/estimated_price_utils";

type Region = {id: number; type: number};

interface IHook {
    isRegionValidating: boolean;
    validatePropertyEstimatedPriceRegion: (region: Region) => void;
}

export const usePropertyEstimatedPriceRegionValidation = (
    fetchEstimatedPriceData: (estimatedPriceQuery: Record<string, string | number>) => void,
    setApiFieldWithAvgPrice: (field: ApiFieldToEstimatePriceType) => void,
    onRegionValidationFail?: () => void
): IHook => {
    const [isRegionValidating, setIsRegionValidating] = useState<boolean>(true);

    const validatePropertyEstimatedPriceRegion = (region: Region) => {
        if (region) {
            if (region.type === RegionType.CITY) {
                if (!!cityWithEstimatedPriceStats.get(region.id)) {
                    fetchEstimatedPriceData({region_id: region.id});

                    setIsRegionValidating(false);
                } else {
                    onRegionValidationFail?.();
                }

                return;
            }

            if (region.type > RegionType.CITY) {
                const shouldBreakRegionFetching = (region: IRegion): boolean => {
                    return region.type <= RegionType.CITY;
                };

                fetchRegionTree(region.id, shouldBreakRegionFetching)
                    .then((regions) => {
                        let cityRegionSlug: string | null = null;
                        let cityRegionId: number | null = null;
                        let districtRegionSlug: string | null = null;

                        regions.forEach((region) => {
                            if (cityWithEstimatedPriceStats.has(region.id)) {
                                cityRegionSlug = cityWithEstimatedPriceStats.get(region.id) ?? null;
                                cityRegionId = region.id;
                            }
                            if (districtsWithEstimatedPriceStats.has(region.id)) {
                                const districtRecord = districtsWithEstimatedPriceStats.get(region.id) ?? null;
                                districtRegionSlug = districtRecord?.district ?? null;
                                cityRegionSlug = districtRecord?.city ?? null;
                            }
                        });

                        if (cityRegionSlug && cityRegionId) {
                            const query: Record<string, string | number> = {region_id: cityRegionId};

                            if (districtRegionSlug) {
                                query.slug_district = districtRegionSlug;
                                query.slug_city = cityRegionSlug;

                                delete query.region_id;

                                setApiFieldWithAvgPrice(DEFAULT_API_FIELD_TO_ESTIMATE_PRICE);
                            }

                            fetchEstimatedPriceData(query);
                        } else {
                            onRegionValidationFail?.();
                        }
                    })
                    .finally(() => setIsRegionValidating(false));
            }
        }
    };

    return {
        isRegionValidating,
        validatePropertyEstimatedPriceRegion
    };
};
