import {css, useTheme} from "@emotion/react";

import {borderRadius, calculateRemSize as crs, onDesktop, p} from "@pg-design/helpers-css";
import {Image} from "@pg-design/image";
import {Text} from "@pg-design/text";

import {flatsPluralize} from "../../../real_estate/constants/flats_pluralize";
import {IRecommendedProperty} from "../../../recommendations/types/IRecommendedProperty";
import {VendorMultileadFittingChart} from "./VendorMultileadFittingChart";

interface IProps {
    vendorGroup: IRecommendedProperty[];
    recommendationsFitting: number;
}

export function VendorMultileadGroup(props: IProps) {
    const {vendorGroup, recommendationsFitting} = props;

    const {logo: vendorLogo, name: vendorName} = vendorGroup[0].offer.vendor;
    const recommendationAmount = vendorGroup.length;

    const theme = useTheme();

    return (
        <div css={vendorGroupStyle}>
            <Image src={vendorLogo.v_log_80x60} css={vendorLogoStyle} imageCss={logoImg} width="80px" height="60px" alt={vendorName} />
            <div css={groupContent}>
                <div css={groupDescription}>
                    <Text variant="body_copy_2" strong>
                        {vendorName}
                    </Text>
                    <Text variant="info_txt_1" color={theme.colors.gray[800]}>
                        {recommendationAmount} dostępne {flatsPluralize(recommendationAmount)}
                    </Text>
                </div>
                <VendorMultileadFittingChart fittingPercentage={recommendationsFitting} />
            </div>
        </div>
    );
}

// Styles
const vendorGroupStyle = css`
    background: white;
    ${borderRadius(2)};
    overflow: hidden;
    display: flex;
    gap: ${crs(1)};
    ${p(1)};
    min-height: 90px;
    align-items: center;

    ${onDesktop(css`
        min-height: 76px;
    `)}
`;

const vendorLogoStyle = css`
    width: ${crs(8)};
    height: ${crs(6)};

    ${onDesktop(css`
        width: ${crs(10)};
        height: ${crs(7.5)};
    `)}
`;

const logoImg = css`
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
`;

const groupDescription = css`
    display: flex;
    flex-direction: column;
    gap: ${crs(0.5)};
    flex: 1 1 auto;
`;

const groupContent = css`
    display: flex;
    flex-direction: column;
    gap: ${crs(2)};
    flex: 1 1 auto;

    ${onDesktop(css`
        flex-direction: row;
        align-items: center;
    `)}
`;
