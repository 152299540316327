import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const BeachIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M2.133 3.209a1.076 1.076 0 0 1 0-2.15 1.076 1.076 0 0 1 0 2.15Zm0-1.62a.545.545 0 1 0 .001 1.09.545.545 0 0 0-.001-1.09ZM7.228 2.659a2.886 2.886 0 0 0-2.185-.08 2.755 2.755 0 0 0-1.58 1.47l-.109.241 2.37 1.06-1.81 3.592h.59l1.705-3.375 2.386 1.066.108-.242c.631-1.412-.03-3.087-1.475-3.732ZM4.07 4.029a2.233 2.233 0 0 1 1.157-.952 2.34 2.34 0 0 1 1.017-.133c-.513.34-.92.854-1.2 1.52L4.07 4.03Zm2.667 1.193L5.527 4.68c.31-.745.778-1.248 1.396-1.5.225.629.163 1.314-.186 2.04Zm1.577.704L7.22 5.438c.32-.674.43-1.342.324-1.967.727.59 1.046 1.563.769 2.455Z" />
        </SvgIcon>
    );
};
