import {useCallback, useState} from "react";
import {sub} from "date-fns";

import {dateTimeFormat, formatDate} from "@pg-mono/string-utils";

import {fetchPropertyEstimatedPrice, IFetchPropertyEstimatedPriceRequestParams, IPropertyEstimatedPrice} from "../actions/fetch_property_estimated_price";
import {ApiFieldToEstimatePriceType, DEFAULT_API_FIELD_TO_ESTIMATE_PRICE} from "../utils/estimated_price_utils";

interface IHook {
    apiFieldWithAvgPrice: ApiFieldToEstimatePriceType;
    estimatedPriceData: IPropertyEstimatedPrice[];
    fetchEstimatedPriceData: (estimatedPriceQuery: Record<string, string | number>) => void;
    isDataLoading: boolean;
    setApiFieldWithAvgPrice: (field: ApiFieldToEstimatePriceType) => void;
    setIsDataLoading: (state: boolean) => void;
}

export const useFetchPropertyEstimatedPriceData = (): IHook => {
    const [isDataLoading, setIsDataLoading] = useState<boolean>(true);
    const [estimatedPriceData, setEstimatedPriceData] = useState<IPropertyEstimatedPrice[]>([]);
    const [apiFieldWithAvgPrice, setApiFieldWithAvgPrice] = useState<ApiFieldToEstimatePriceType>(DEFAULT_API_FIELD_TO_ESTIMATE_PRICE);

    const fetchEstimatedPriceData = useCallback(
        (estimatedPriceQuery: Omit<IFetchPropertyEstimatedPriceRequestParams, "date_start" | "date_end" | "scenario"> & {startDate?: Date}) => {
            const {startDate, ...restEstimatedPriceQuery} = estimatedPriceQuery;

            // Statistics DB hasn't full data for current month, so we need to subtract one month from current date.
            const endDate = sub(new Date(), {months: 1});

            const query = {
                ...restEstimatedPriceQuery,
                date_start: formatDate((startDate || sub(endDate, {months: 6})).toISOString(), dateTimeFormat.dashedDateFormat),
                date_end: formatDate(endDate.toISOString(), dateTimeFormat.dashedDateFormat),
                scenario: "rp-price-avg"
            };

            return fetchPropertyEstimatedPrice(query, !!query.slug_district)
                .then((response) => {
                    /*
                     * Analytics database can have missing data for specific months. In such case we need to make fallback
                     * to default API field for estimated prices.
                     */
                    const isRequiredDataMissing = response.some((item) => !!item[apiFieldWithAvgPrice]);

                    if (!query.slug_district && isRequiredDataMissing) {
                        setApiFieldWithAvgPrice(DEFAULT_API_FIELD_TO_ESTIMATE_PRICE);
                    }

                    setEstimatedPriceData(response);
                })
                .finally(() => setIsDataLoading(false));
        },
        []
    );

    return {
        apiFieldWithAvgPrice,
        estimatedPriceData,
        fetchEstimatedPriceData,
        isDataLoading,
        setApiFieldWithAvgPrice,
        setIsDataLoading
    };
};
