import {apiPath} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IApplication} from "../../application/types/IApplication";
import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";

export const createLeadApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        createLead: build.mutation<IApplication, Partial<IApplication>>({
            query: (body) => ({
                url: apiPath.applications.create.base,
                method: "POST",
                body
            }),
            transformErrorResponse: (data) => {
                const error = `createLeadApi error: ${data.status}`;
                notifyBugsnag(error, error);

                return data;
            }
        }),
        createMultipleLeads: build.mutation<IApplication[], Partial<IApplication>[]>({
            queryFn: async (leads, _api, _extraOptions, fetchWithBQ) => {
                const leadRequests = leads.map((lead) =>
                    fetchWithBQ({
                        url: apiPath.applications.create.base,
                        body: lead,
                        method: "POST"
                    })
                );

                const leadResponses = await Promise.all(leadRequests);
                const leadErrors = leadResponses.filter((response) => response.error);

                if (leadErrors.length > 0) {
                    const errorMsg = `createMultipleLeads error: ${leadErrors.map((errorResponse) => errorResponse.error).join(", ")}`;
                    notifyBugsnag(errorMsg, errorMsg);

                    return {
                        error: {
                            reason: errorMsg,
                            data: leadErrors.map((errorResponse) => errorResponse.data),
                            status: 400
                        }
                    };
                }

                return {
                    data: leadResponses.map((response) => response.data as IApplication)
                };
            }
        })
    })
});

export const {useCreateMultipleLeadsMutation, useCreateLeadMutation} = createLeadApi;
