import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {borderRadius, calculateRemSize as crs} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

interface IProps {
    fittingPercentage: number;
}

export function VendorMultileadFittingChart(props: IProps) {
    const {fittingPercentage} = props;

    return (
        <div css={fittingChartWrap}>
            <div css={fittingChartStyle}>
                <div css={backBar}>
                    <FrontBar percentage={fittingPercentage} />
                </div>
            </div>
            <Text variant="info_txt_3">
                Dopasowanie: <strong>{fittingPercentage}%</strong>
            </Text>
        </div>
    );
}

// Styles
const fittingChartWrap = css`
    display: flex;
    flex-direction: column;
    gap: ${crs(0.5)};
`;
const fittingChartStyle = css`
    display: flex;
    height: ${crs(1)};
    position: relative;
    width: 102px;
`;

const backBar = (theme: Theme) => css`
    width: 100%;
    background: ${theme.colors.gray[800]};
    ${borderRadius(1)};
`;

const FrontBar = styled.div<{percentage: number}>`
    ${({percentage, theme}) => css`
        width: ${percentage}%;
        height: 100%;
        background: ${theme.colors.success};
        ${borderRadius(1)};
        position: absolute;
        inset: 0 auto auto 0;
    `}
`;
