import {SerializedStyles} from "@emotion/react";

import {Highlight, Text} from "@pg-design/text";
import {priceFormat} from "@pg-mono/string-utils";

interface IProps {
    price: string | number;
    customStyle?: SerializedStyles | SerializedStyles[];
}

export const HighlightedPriceFromTo = (props: IProps) => {
    const price = typeof props.price === "number" ? props.price : parseFloat(props.price);
    return (
        <Text as="p" variant="headline_6" css={props.customStyle}>
            <Highlight>
                <Text as="span" variant="body_copy_2">
                    od{" "}
                </Text>
                {priceFormat(price)}{" "}
                <Text as="span" variant="body_copy_2">
                    zł/m2
                </Text>
            </Highlight>
        </Text>
    );
};
