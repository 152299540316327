import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {borderRadius, flexAbsoluteCenter, flexDirection, mb, p} from "@pg-design/helpers-css";
import {Loader} from "@pg-design/loader";
import {Text} from "@pg-design/text";
import {areaFormat, pluralize} from "@pg-mono/string-utils";
import {priceFormat} from "@pg-mono/string-utils";

import {getOfferTypeNameCapital} from "../../../../../offer/helpers/OfferType";
import {Country} from "../../../../../region/types/Country";
import {IUseEstimatedPriceByAreaResult} from "../../../../hooks/use_estimated_price_by_area";
import {IMultiLeadData} from "../../../../types/IMultiLeadData";

interface IProps extends IMultiLeadData {
    estimatedPriceProps: IUseEstimatedPriceByAreaResult;
}

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

export const MultiLeadCardEstimatedPrice = (props: IProps) => {
    const theme = useTheme();
    const displayType = getOfferTypeNameCapital(props.offerType);
    const {isEstimatedPriceLoading, estimatedPriceMin, estimatedPriceMax} = props.estimatedPriceProps;
    const priceFormatWithUnit = (price: number) => priceFormat(price, {unit: props.region.country === Country.POLAND ? "zł" : "eur"});

    return (
        <div css={mainWrapperStyle}>
            {isEstimatedPriceLoading ? (
                <div css={[flexAbsoluteCenter, flexDirection("column"), p(2, 3)]}>
                    <Loader size="lg" />
                    <Text variant="body_copy_2" align="center" css={mb(2)}>
                        Trwa sprawdzanie <br /> estymowanej ceny...
                    </Text>
                </div>
            ) : (
                <div css={[p(0.5, 2)]}>
                    <Text variant="body_copy_1">{`${displayType}, ${props.rooms ? `${props.rooms} ${roomsPluralize(props.rooms)}, ` : ""}${
                        props.area ? areaFormat(props.area) : ""
                    }`}</Text>
                    <Text variant="info_txt_3" color={theme.colors.gray[800]} css={mb(1)}>
                        {props.offerVendorName}
                    </Text>
                    <Text variant="info_txt_2" color={theme.colors.gray[800]} css={mb(2)}>
                        {props.offerAddress}
                    </Text>

                    {props.isPriceRevealed && props.price && props.price > 0 ? (
                        <>
                            <Text variant="info_txt_2" css={mb(0.5)}>
                                Cena:
                            </Text>
                            <Text variant="body_copy_2" css={estimatedPricesWrapperStyle}>
                                {priceFormatWithUnit(props.price)}
                            </Text>
                        </>
                    ) : estimatedPriceMin || estimatedPriceMax ? (
                        <>
                            <Text variant="info_txt_2" css={mb(0.5)}>
                                Cena estymowana:
                            </Text>
                            <Text variant="body_copy_2" css={estimatedPricesWrapperStyle}>
                                {estimatedPriceMin ? priceFormatWithUnit(estimatedPriceMin) : null}
                                {estimatedPriceMin && estimatedPriceMax ? ` - ` : null}
                                {estimatedPriceMax ? priceFormatWithUnit(estimatedPriceMax) : null}
                            </Text>
                        </>
                    ) : null}
                </div>
            )}
        </div>
    );
};

const mainWrapperStyle = css`
    flex: 1 1 auto;
`;

const estimatedPricesWrapperStyle = (theme: Theme) => css`
    display: inline-flex;
    background-color: ${theme.colors.success};
    ${borderRadius(2)};
    color: ${theme.colors.secondary};
    ${p(0.5, 1)};
`;
