import React from "react";
import {css} from "@emotion/react";

import {calculateRemSize, flex, ml, pb} from "@pg-design/helpers-css";
import {AreaIcon, CalendarClearCheckIcon} from "@pg-design/icons";
import {Text} from "@pg-design/text";
import {upperFirst} from "@pg-mono/nodash";
import {dateTimeFormat, formatFutureDate} from "@pg-mono/string-utils";

import {getAreaComparisonText} from "../../../../utils/get_area_comparison_text";

interface IProps {
    area?: number;
    compareAreaTo?: number;
    constructionDateRange?: {upper: string};
    equalColumns?: boolean;
    className?: string;
}

export const MultiLeadCardPictograms = (props: IProps) => {
    const {className, area, compareAreaTo, constructionDateRange} = props;

    return (
        <div css={[pictogramsContainer, props.equalColumns ? pictogramsContainerEqualColumns : ""]} className={className}>
            {compareAreaTo && area ? (
                <div css={[pictogramContainer, props.equalColumns ? pictogramContainerEqualColumns : ""]}>
                    <AreaIcon size="1.6" wrapperSize="2.2" wrapperType="square" wrapperColor="transparent" />
                    <Text variant="body_copy_2" strong css={ml(0.5)}>
                        {upperFirst(getAreaComparisonText(compareAreaTo, area))}
                    </Text>
                </div>
            ) : null}
            {constructionDateRange ? (
                <div css={[pictogramContainer, props.equalColumns ? pictogramContainerEqualColumns : ""]}>
                    <CalendarClearCheckIcon size="1.6" wrapperSize="2.2" wrapperType="square" wrapperColor="transparent" />
                    <Text variant="body_copy_2" strong css={ml(0.5)}>
                        {upperFirst(formatFutureDate(constructionDateRange.upper, dateTimeFormat.quarterLong))}
                    </Text>
                </div>
            ) : null}
        </div>
    );
};

const pictogramsContainer = css`
    ${flex("center", "flex-start")};
    gap: ${calculateRemSize(4)};
    ${pb(1)};
`;

const pictogramsContainerEqualColumns = css`
    gap: ${calculateRemSize(2)};
`;

const pictogramContainer = css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const pictogramContainerEqualColumns = css`
    flex: 1 1 auto;
`;
