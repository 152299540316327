import * as React from "react";
import {css, Theme} from "@emotion/react";

import {Button} from "@pg-design/button";
import {mb, p} from "@pg-design/helpers-css";
import {Link} from "@pg-design/link";
import {Text} from "@pg-design/text";
import {kmAppLink} from "@pg-mono/km-routes";

import {getFullKMLink} from "../../utils/get_full_km_link";

interface IProps {
    className?: string;
    onClick: () => void;
    buttonClassName?: string;
    onClickShowSentApplications: () => void;
    disableRedirectButton?: boolean;
    isModal?: boolean;
    isAuthenticated: boolean;
    successText?: string;
    onModalClose?: () => void;
}

export class FormSuccessInfo extends React.Component<IProps, {}> {
    public static defaultProps = {
        buttonColor: "success",
        disableRedirectButton: false
    };

    public render() {
        return (
            <div css={holder}>
                <Text variant="headline_3" css={headingStyle}>
                    {this.props.successText ? this.props.successText : "Twoje zgłoszenie zostało wysłane do dewelopera"}
                </Text>
                {this.props.isAuthenticated && (
                    <Text variant="body_copy_1" css={subheadingStyle}>
                        Możesz teraz odczytać je na{" "}
                        <Link size="big" href={getFullKMLink(kmAppLink.site.offers.appliedWithParams)} css={underline}>
                            swoim koncie
                        </Link>
                    </Text>
                )}

                <div css={buttonsHolder}>
                    {!this.props.disableRedirectButton && this.props.isAuthenticated && (
                        <Button variant="filled_primary" onClick={this.props.onClickShowSentApplications} css={submitBtn}>
                            Zobacz zgłoszenie
                        </Button>
                    )}

                    <Button variant="none_secondary" onClick={this.props.onClick}>
                        Powrót
                    </Button>
                </div>
            </div>
        );
    }
}

const holder = (theme: Theme) => css`
    ${p(6, 2, 6, 2)};
    position: relative;

    @media (min-width: ${theme.breakpoints.md}) {
        ${p(5)};
    }
`;

const headingStyle = (theme: Theme) => css`
    ${mb(4)};
    color: ${theme.colors.gray[800]};
    text-align: left;

    @media (min-width: ${theme.breakpoints.md}) {
        ${mb(4)};
        text-align: center;
    }
`;

const subheadingStyle = (theme: Theme) => css`
    color: ${theme.colors.gray[800]};
    margin-bottom: 8rem;
    text-align: left;

    @media (min-width: ${theme.breakpoints.md}) {
        ${mb(4)}
        text-align: center;
    }
`;

const buttonsHolder = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${theme.breakpoints.md}) {
        flex-direction: row;
        justify-content: center;
    }
`;

const submitBtn = (theme: Theme) => css`
    margin-bottom: 2rem;
    @media (min-width: ${theme.breakpoints.md}) {
        margin-right: 2rem;
        margin-bottom: 0;
    }
`;

const underline = (theme: Theme) => css`
    text-decoration: underline;
    color: ${theme.colors.gray[800]};
`;
