export enum LeadModalStep {
    APPLICATION_FORM,
    NOTIFICATION_CONSENT,
    SUCCESS_INFO,
    GOODBYE_INFO,
    RECOMMENDED_OFFERS,
    SINGLE_LEAD_SUMMARY,
    MULTILEAD_SUMMARY,
    ARCHITECT_OFFER,
    APPLIED_ARCHITECT_OFFER,
    ARCHITECT_MULTILEAD,
    PM_OFFER,
    PM_APPLIED_OFFER,
    CLOSE
}
