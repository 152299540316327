import type {Dispatch} from "redux";

/*
    State
 */
export interface ILeadFormState {
    didSubmitOnMount: boolean;
}

const initialState: ILeadFormState = {
    didSubmitOnMount: false
};

/*
    Reducers
 */
export function leadFormReducer(state = initialState, action: ISentLeadActions) {
    switch (action.type) {
        case LeadFormActionTypes.SET_DID_SUBMIT:
            return {
                ...state,
                didSubmitOnMount: action.payload
            };
        default:
            return state;
    }
}

/*
    Actions
 */
enum LeadFormActionTypes {
    SET_DID_SUBMIT = "SET_DID_SUBMIT"
}

export type ISentLeadActions = ISetSentLeadValuesAction;

interface ISetSentLeadValuesAction {
    type: LeadFormActionTypes.SET_DID_SUBMIT;
    payload: boolean;
}

export function setDidSubmitOnMountAction(payload: boolean): ISetSentLeadValuesAction {
    return {
        type: LeadFormActionTypes.SET_DID_SUBMIT,
        payload
    };
}

export const setDidSubmitOnMount = (payload: boolean) => (dispatch: Dispatch<ISetSentLeadValuesAction>) => {
    dispatch(setDidSubmitOnMountAction(payload));
};
