import {useMemo} from "react";

import {convertToLatLngLiteralOfPoland, ICircle, LazyOpenStreetMap} from "@pg-mono/open-street-map";

import {VENDOR_MULTI_LEAD_DISTANCE_IN_KM} from "../../../application/constants/multi_lead_constants";

const offerPin = require("../../../maps/images/pins/offer_pin.svg");

interface IProps {
    center: [number, number];
    mapRecommendations: IMapRecommendation[];
}

type IMapRecommendation = {
    logoImg: string;
    alt: string;
    geoPoint: [number, number];
};

export function VendorMultileadMap(props: IProps) {
    const {center, mapRecommendations} = props;

    const radius = VENDOR_MULTI_LEAD_DISTANCE_IN_KM * 1000;
    const mapCircles: ICircle[] = useMemo(
        () => [
            {
                center: {
                    lat: center[1],
                    lng: center[0]
                },
                radius,
                pathOptions: {
                    color: "#EBFF00",
                    opacity: 0.3
                }
            }
        ],
        []
    );

    const markers = useMemo(
        () =>
            mapRecommendations.map((recommendation) => ({
                id: recommendation.geoPoint.join(","),
                icon: {
                    url: offerPin,
                    sizes: [32, 32] as [number, number]
                },
                coords: convertToLatLngLiteralOfPoland(recommendation.geoPoint)
            })),
        []
    );

    return <LazyOpenStreetMap circles={mapCircles} markers={markers} />;
}
