import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export enum GTMEvent {
    ARCHITECT_GALLERY_FILTER = "architect_gallery_filter",
    ARCHITECT_GALLERY_OPEN = "architect_gallery_open",
    ARCHITECT_PACKAGE_CLICK = "architect_package_click",
    ARCHITECT_PACKAGE_SHOW_CLICK = "architect_package_show_click",
    ARCHITECT_FORM_AUTOFILL = "architect_form_autofill",
    ARCHITECT_FORM_INTERACTION = "architect_form_interaction",
    ARCHITECT_FORM_SEND = "architect_form_send"
}

const architectDetailsEvent = (event: GTMEvent, data = {}) => {
    const eventData = {event, ...data};

    hitGoogleTagManager(eventData);
};

export const architectDetailsTracking = {
    gtm: {
        architectDetailsEvent
    }
};
