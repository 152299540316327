import {getPropertyList} from "../../property/api/get_property_list";
import {propertyListConstraints} from "../../property/constants/property_list_constraints";
import {getSimilarProperties} from "../../recommendations/api/get_recommended_properties";
import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";
import {IAppDispatch} from "../../store/store";
import {IApplicationProperty} from "../types/IApplicationProperty";
import {IOriginalApplicationProperty} from "../types/IOriginalApplicationProperty";

interface IFetchVendorRecommendationsParams {
    excludedOfferIds: number[];
    radius: number;
    offerId?: number | null;
    originalApplicationProperty?: IApplicationProperty | null;
    filters?: Record<string, string>;
}

export async function fetchVendorRecommendations(
    params: IFetchVendorRecommendationsParams,
    dispatch: IAppDispatch
): Promise<{
    results: IRecommendedProperty[];
    leadProperty: IOriginalApplicationProperty;
}> {
    const {originalApplicationProperty, offerId, filters = {}, excludedOfferIds, radius} = params;
    const pageSize = 12;

    const propertyId = originalApplicationProperty?.id;
    if (propertyId) {
        try {
            const recommendedProperties = await dispatch(
                getSimilarProperties.initiate({propertyId, radius, page_size: pageSize, excludedOfferIds, ...filters})
            ).unwrap();
            return {
                results: recommendedProperties.results,
                leadProperty: originalApplicationProperty
            };
        } catch (_) {
            return {
                results: [],
                leadProperty: null
            };
        }
    }

    if (offerId) {
        const propertyListRes = await dispatch(getPropertyList.initiate({offer: offerId, ...propertyListConstraints})).unwrap();
        const firstProperty = propertyListRes.results[0];

        try {
            const recommendedPropertiesFromOffer = await dispatch(
                getSimilarProperties.initiate({propertyId: firstProperty.id, radius, page_size: pageSize, excludedOfferIds, ...filters})
            ).unwrap();

            return {
                results: recommendedPropertiesFromOffer.results,
                leadProperty: firstProperty
            };
        } catch (_) {
            return {
                results: [],
                leadProperty: null
            };
        }
    }

    return {
        results: [],
        leadProperty: null
    };
}
