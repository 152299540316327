import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {isPlainObject} from "@pg-mono/nodash";

import {IABTestingState} from "../interfaces/IABTestingState";
import {useABTestVariant} from "./useEperimentVariant";

export interface IProps {
    disableEventPush?: boolean;
    pushEventOnlyOnce?: true;
    id: string;
    variants: number;
    withSSR?: boolean;
}

export const useABTest = (props: IProps) => {
    const [isClient, setIsClient] = useState<boolean>(false);

    const variant = useABTestVariant(props.id);

    const isInitialized = useSelector((state: IABTestingState) => state.abTesting.isInitialized);

    useEffect(() => {
        if (window) {
            const isVariantValid = typeof variant === "number" && Number.isFinite(variant) && variant <= props.variants;

            if (!props.disableEventPush && isVariantValid) {
                window.dataLayer = window.dataLayer || [];

                if (!props.pushEventOnlyOnce) {
                    window.dataLayer.push({event: "experience_impression", exp_variant_string: `${props.id}.${variant}`});
                }

                const hasReportedExperiment = window.dataLayer.some(
                    (item: {exp_variant_string?: string}) =>
                        isPlainObject(item) && "exp_variant_string" in item && item.exp_variant_string?.startsWith(props.id)
                );
                if (props.pushEventOnlyOnce && !hasReportedExperiment) {
                    window.dataLayer.push({event: "experience_impression", exp_variant_string: `${props.id}.${variant}`});
                }
            }

            /*
             * If variant is not valid and ABTesting instance was initialized that means that variant for AB test was not generated.
             * The reason may be wrong `paths` configuration for AB Test. In such cases we need to render default (props.children[0]) component.
             */
            if ((!props.withSSR && !isClient && isVariantValid) || (!isVariantValid && !isClient && isInitialized)) {
                setIsClient(true);
            }
        }
    }, [variant]);

    return {
        canABTestBeRendered: props.withSSR || isClient,
        abTestVariant: variant
    };
};
