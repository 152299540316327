import {HTMLAttributes, PropsWithChildren} from "react";
import {css, Theme, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {elevation} from "@pg-design/elevation";
import {flexAbsoluteCenter} from "@pg-design/helpers-css";
import {CheckboxCheckedIcon, CheckboxIcon} from "@pg-design/icons";

type IProps = {
    selected: boolean;
    onChange: (selected: boolean) => void;
    className?: string;
} & Omit<HTMLAttributes<HTMLDivElement>, "onChange" | "className">;

export const Selectable = (props: PropsWithChildren<IProps>) => {
    const {onChange, selected, className, children, ...htmlProps} = props;
    const theme = useTheme();

    return (
        <div css={container} {...htmlProps}>
            <Children className={className} selected={selected}>
                {children}
            </Children>

            <Select onClick={() => onChange(!selected)} selected={selected}>
                {selected ? <CheckboxCheckedIcon fill="#02D054" size="2.4" /> : <CheckboxIcon fill={theme.colors.gray[700]} size="2.4" />}
            </Select>
        </div>
    );
};

const container = (theme: Theme) => css`
    width: max-content;
    position: relative;

    @media (min-width: ${theme.breakpoints.md}) {
        width: 100%;
    }
`;

const Children = styled.div<Pick<IProps, "selected">>`
    height: calc(100% + 3.6rem);
    transition: box-shadow 200ms;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        height: auto;
        width: calc(100% - 3.6rem);
    }

    ${(props) =>
        props.selected &&
        css`
            ${elevation(2)}
        `}
`;

const Select = styled.div<Pick<IProps, "selected">>`
    position: absolute;
    left: 50%;
    bottom: -2.8rem;
    transform: translateX(-50%);
    width: 5.6rem;
    height: 5.6rem;
    border: 1px solid ${(props) => props.theme.colors.gray[500]};
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.gray[100]};
    cursor: pointer;
    ${flexAbsoluteCenter};
    transition: border-color, box-shadow 200ms;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
        inset: unset;
        top: 50%;
        right: 0.8rem;
        transform: translateY(-50%);
    }
  
    ${(props) =>
        props.selected &&
        css`
            border-color: transparent;
            ${elevation(2)};
        `}}
`;
