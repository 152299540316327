import {Dispatch} from "redux";

import {catch400, catch403, postRequest, Response400} from "@pg-mono/request";
import {apiPath} from "@pg-mono/rp-api-routes";

import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";
import {addNotification} from "../../notifications/state/notifications_slice";
import {IApplication} from "../types/IApplication";
import {alertFormSuccess} from "../utils/alert_text";

interface IPropertyConsultingOptions {
    on400Response?: (error: Response400) => void;
}

const applicationApiLink = apiPath.applications.create.base;

export const postRecommendedApplication =
    (jsonData: Partial<IApplication>, options: IPropertyConsultingOptions = {}) =>
    (dispatch: Dispatch): Promise<IApplication | void> => {
        const response = postRequest({}, applicationApiLink, jsonData)
            .then((application: IApplication) => {
                dispatch(addNotification(alertFormSuccess));
                return application;
            })
            .catch(
                catch403((error) => {
                    notifyBugsnag(error, "postRecommended: catch 403", error.responseError ?? {});
                })
            );

        if (options.on400Response) {
            response.catch(
                catch400((error) => {
                    options.on400Response && options.on400Response(error);
                })
            );
        }

        return response;
    };
