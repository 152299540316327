import {apiV2Link} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IGetPropertyListPayload} from "../../property/types/GetPropertyListPayload";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IPaginatedRequest} from "../../types/IPaginatedRequest";
import {IRecommendedProperty} from "../types/IRecommendedProperty";

type IGetSimilarPropertiesPayload = IGetPropertyListPayload &
    Partial<IPaginatedRequest> & {
        propertyId: number;
        excludedOfferIds: number[];
        radius?: number;
    };

interface IGetSimilarPropertiesResponse extends IPaginatedResponse<IRecommendedProperty> {}

export const getRecommendedPropertiesApi = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getSimilarProperties: build.query<IGetSimilarPropertiesResponse, IGetSimilarPropertiesPayload>({
            query: ({propertyId, page = 1, excludedOfferIds, ...params}) => ({
                url: apiV2Link.property.similar({propertyId}),
                params: {
                    page,
                    exclude_offer: excludedOfferIds,
                    ...params
                }
            }),
            transformErrorResponse: (_a, _b, {page, page_size}) => {
                return {
                    page,
                    count: 0,
                    next: null,
                    previous: null,
                    page_size,
                    results: []
                } as IPaginatedResponse;
            }
        })
    })
});

export const {getSimilarProperties} = getRecommendedPropertiesApi.endpoints;
