import {useSelector} from "react-redux";
import {css, useTheme} from "@emotion/react";

import {calculateRemSize, flex, flexDirection, mt, onDesktop} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {IRPStore} from "../../../app/rp_reducer";
import {IMarketplaceArchitectOfferSummary} from "../../../architect_application/api/fetch_marketplace_architect_promoted_offers_by_region";
import {useMarketplaceArchitectOfferForRegion} from "../../../architect_application/hooks/use_marketplace_architect_offer_summary";
import {IRegionAncestor} from "../../../offer/list/actions/offer_list_selected_data_actions";
import {AdditionalOfferCategoryGTMEventAction, multiLeadAdditionalOfferAnalytics} from "../../tracking/multilead_additional_offers_tracking";
import {MultiLeadPromoBox} from "./MultiLeadPromoBox";

const multiLeadOffersPromoImg = require("../../images/multi_lead_promo/multi_lead_offers_promo.png");
const multiLeadArchitectPackagesPromoImg = require("../../images/multi_lead_promo/multi_lead_architect_packages_promo.png");

interface IProps {
    storeHash: string;
    onPMOfferClick: () => void;
    onArchitectPackagesClick: (architectData: IMarketplaceArchitectOfferSummary, architectRegion: IRegionAncestor | null) => void;
    className?: string;
}

export const MultiLeadPromoSection = (props: IProps) => {
    const theme = useTheme();

    const mainLeadData = useSelector((store: IRPStore) => store.application[props.storeHash]);
    const offer = mainLeadData?.offer?.detail;
    const property = mainLeadData?.property?.detail;
    const {architectOfferSummary, architectRegion} = useMarketplaceArchitectOfferForRegion({
        propertyId: property?.id,
        offerId: offer?.id
    });

    const onPmOfferClick = () => {
        multiLeadAdditionalOfferAnalytics.gtm.multiLeadAdditionalOfferEvent(AdditionalOfferCategoryGTMEventAction.PM);
        props.onPMOfferClick();
    };

    const onArchitectPackagesClick = () => {
        multiLeadAdditionalOfferAnalytics.gtm.multiLeadAdditionalOfferEvent(AdditionalOfferCategoryGTMEventAction.ARCHITECTS);

        if (architectOfferSummary) {
            props.onArchitectPackagesClick(architectOfferSummary, architectRegion);
        }
    };

    return (
        <div className={props.className}>
            <Text variant="headline_5" mb="0.4rem" align="center">
                Co dalej?
            </Text>
            <Text variant="body_copy_2" color={theme.colors.gray[700]} align="center">
                Mamy dla Ciebie jeszcze coś ekstra...
            </Text>
            <div css={boxWrapperStyle}>
                <MultiLeadPromoBox
                    imgSrc={multiLeadOffersPromoImg}
                    text={
                        <>
                            Skorzystaj z doświadczenia naszych ekspertów rynku nieruchomości. <strong>Uzyskaj dostęp do dodatkowych ofert.</strong>
                        </>
                    }
                    onClick={onPmOfferClick}
                    css={promoBoxStyle}
                />

                {architectOfferSummary ? (
                    <MultiLeadPromoBox
                        imgSrc={multiLeadArchitectPackagesPromoImg}
                        text={
                            <>
                                Zobacz atrakcyjne pakiety wykończenia <strong>pod klucz z gwarancją ceny i terminu!</strong>
                            </>
                        }
                        onClick={onArchitectPackagesClick}
                        css={promoBoxStyle}
                    />
                ) : null}
            </div>
        </div>
    );
};

const boxWrapperStyle = css`
    ${mt(2)};
    ${flex()};
    ${flexDirection("column")};
    gap: ${calculateRemSize(3)};
    position: relative;

    ${onDesktop(css`
        ${flexDirection("row")};
        justify-content: center;
    `)};
`;

const promoBoxStyle = css`
    ${onDesktop(css`
        max-width: 50%;
    `)};
`;
