import {IApplicationOffer} from "../../application/types/IApplicationOffer";
import {getAvailableAndSearchedRooms} from "../../application/utils/getAvailableAndSearchedRooms";
import {IUserPreferences} from "../../user/interfaces/IUserPreferences";

interface ICalculateRoomsFieldParams {
    offer?: IApplicationOffer;
    propertyId?: number;
    preventStorageOfferSearchLoad?: boolean;
    userPreferencesFilters: IUserPreferences["filters"];
}

export function calculateRoomsField(params: ICalculateRoomsFieldParams) {
    const {offer, propertyId, preventStorageOfferSearchLoad} = params;
    const availableRooms = (offer && offer.stats && offer.stats.rooms) || [];
    return propertyId ? [] : preventStorageOfferSearchLoad ? availableRooms : getAvailableAndSearchedRooms(availableRooms, params.userPreferencesFilters);
}
