import React, {useEffect, useState} from "react";
import {css, Theme} from "@emotion/react";
import styled from "@emotion/styled";

import {compact} from "@pg-mono/nodash";

import {fadeInAnimation} from "../../../search/components/atoms/atoms";
import {fetchApplicationOfferDetailById} from "../../api/fetch_application_offer_detail_by_id";
import {fetchApplicationPropertyDetailById} from "../../api/fetch_application_property_detail_by_id";
import {fetchApplicationVendorDetailById} from "../../api/fetch_application_vendor_detail_by_id";
import {IApplicationOffer} from "../../types/IApplicationOffer";
import {IApplicationProperty} from "../../types/IApplicationProperty";
import {IApplicationVendor} from "../../types/IApplicationVendor";
import {ApplicationSource} from "../../utils/ApplicationSource";
import {ApplicationStyledModal} from "../ApplicationStyledResponsiveModal";
import {ExternalApiApplicationSide} from "./ExternalApiApplicationSide";
import {ExternalApiEchoApplicationForm} from "./ExternalApiEchoApplicationForm";

interface IApplicationData {
    offer: IApplicationOffer | null;
    property: IApplicationProperty | null;
    vendor: IApplicationVendor | null;
}

enum ExternalApis {
    ECHO = 1
}

interface IProps {
    applicationExternalApi: ExternalApis;
    isOpened: boolean;
    offerId?: number;
    onModalClose: () => void;
    propertyId?: number;
    source: ApplicationSource;
    vendorId?: number;
}

export const ExternalApiApplicationModal = (props: IProps) => {
    const [{offer, property, vendor}, setApplicationData] = useState<IApplicationData>({offer: null, property: null, vendor: null});
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (compact([props.offerId, props.propertyId, props.vendorId]).length) {
            const promises: [Promise<IApplicationOffer | null>, Promise<IApplicationProperty | null>, Promise<IApplicationVendor | null>] = [
                props.offerId ? fetchApplicationOfferDetailById(props.offerId) : Promise.resolve(null),
                props.propertyId ? fetchApplicationPropertyDetailById(props.propertyId) : Promise.resolve(null),
                props.vendorId ? fetchApplicationVendorDetailById(props.vendorId) : Promise.resolve(null)
            ];

            Promise.all(promises)
                .then(([offer, property, vendor]) => setApplicationData({offer, property, vendor}))
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }, [props.offerId, props.propertyId, props.vendorId]);

    const renderExternalApiForm = () => {
        switch (props.applicationExternalApi) {
            case ExternalApis.ECHO: {
                return (
                    <ExternalApiEchoApplicationForm onModalClose={props.onModalClose} offer={offer} property={property} vendor={vendor} source={props.source} />
                );
            }
            default:
                return null;
        }
    };

    return (
        <FormModal isOpen={props.isOpened} onModalClose={props.onModalClose} shouldCloseOnOverlayClick>
            <div css={application}>
                <ExternalApiApplicationSide isLoading={isLoading} offer={offer} vendor={vendor} />

                <div css={applicationForm}>{renderExternalApiForm()}</div>
            </div>
        </FormModal>
    );
};

const FormModal = styled(ApplicationStyledModal)`
    height: 100%;
    ${fadeInAnimation("0.1s")};

    @media (min-width: 1024px) {
        width: unset;
        height: unset;
    }
`;

const application = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: 100%;

    @media (min-width: ${theme.breakpoints.md}) {
        width: 81rem;
        height: 64.8rem;
    }
`;

const applicationForm = (theme: Theme) => css`
    width: 100%;
    min-width: 28rem;
    position: relative;

    @media (min-width: ${theme.breakpoints.md}) {
        width: 46rem;
    }
`;
