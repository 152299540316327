import {hitAlgolytics} from "@pg-mono/algolytics";

import {hitGoogleTagManager} from "../../tracking/google_tag_manager/init_google_tag_manager";

export function hitVendorMultileadSendButton(recommendationCount: number) {
    hitAlgolytics("fixly_send_button_click");

    hitGoogleTagManager({
        event: "fixly",
        fixly_action: "fixly_send_button_click",
        recommendation_count: recommendationCount
    });
}
