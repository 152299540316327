/*
    TODO Consider moving this file to "offer" domain
 */
import {css, useTheme} from "@emotion/react";
import styled from "@emotion/styled";

import {w100} from "@pg-design/helpers-css";
import {Text} from "@pg-design/text";

import {useResponsiveLinkTarget} from "../../../hooks/use_responsive_link_target";

interface IRodoTextProps {
    vendorName: string;
    privacyDataProcessorName: string | null;
    privacyPolicyUrl: string | null;
    personalDataProcessorUrl?: string | null;
    className?: string;
    textVariant?: Parameters<typeof Text>["0"]["variant"];
    align?: Parameters<typeof Text>["0"]["align"];
}

export const RodoText = (props: IRodoTextProps) => {
    const textAfterVendorName = props.vendorName.endsWith(".") ? "" : ".";
    const privacyPolicyUrl = props.personalDataProcessorUrl || props.privacyPolicyUrl;
    const showOfferPersonalDataProcessor = !!props.privacyDataProcessorName && !!props.personalDataProcessorUrl;

    const responsiveLinkTarget = useResponsiveLinkTarget();
    const theme = useTheme();

    return (
        <RodoTextBase className={props.className}>
            <Text align={props.align || "left"} color={theme.colors.gray[700]} variant={props.textVariant || "info_txt_2"} css={w100}>
                Administratorem danych jest {props.vendorName}
                {textAfterVendorName}
                {privacyPolicyUrl && !showOfferPersonalDataProcessor && (
                    <>
                        {" "}
                        Dowiedz się więcej o&nbsp;
                        <span>
                            <a css={linkStyles} href={privacyPolicyUrl} target={responsiveLinkTarget}>
                                przetwarzaniu Twoich danych osobowych
                            </a>
                        </span>
                        .
                    </>
                )}
            </Text>
        </RodoTextBase>
    );
};

const RodoTextBase = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;

    a,
    a:hover {
        color: inherit;
    }

    a:hover {
        text-decoration: underline;
    }
`;

const linkStyles = css`
    &,
    &:link,
    &:visited,
    &:hover,
    &:active {
        color: inherit;
        text-decoration: underline;
    }
`;
