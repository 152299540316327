import {useEffect, useReducer} from "react";

import {IRecommendedOffer} from "../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../recommendations/types/IRecommendedProperty";
import {MultiLeadRecommendationType} from "../types/MultiLeadRecommendationType";
import {selectedRecommendationsReducer} from "../utils/recommended_application/selected_recommendations_reducer";

interface IProps {
    recommendedProperties: IRecommendedProperty[];
    recommendedOffers: IRecommendedOffer[];
    recommendationType: MultiLeadRecommendationType;
    maxRecommendationsShown?: number;
    selectAllOnRefresh?: boolean;
}

export const useRecommendationsSelection = (props: IProps) => {
    const [selectedRecommendations, dispatchSelectedRecommendations] = useReducer(selectedRecommendationsReducer, {
        property: props.recommendedProperties.slice(0, props.maxRecommendationsShown || props.recommendedProperties.length).map((item) => item.id),
        offer: props.recommendedOffers.slice(0, props.maxRecommendationsShown || props.recommendedOffers.length).map((item) => item.id)
    });

    useEffect(() => {
        if (props.selectAllOnRefresh) {
            selectAll();
        }
    }, [props.recommendedProperties, props.recommendedOffers]);

    const selectedRecommendationsCount =
        props.recommendationType === MultiLeadRecommendationType.PROPERTY ? selectedRecommendations.property.length : selectedRecommendations.offer.length;

    const isRecommendationSelected = (recommendationId: number): boolean => {
        switch (props.recommendationType) {
            case MultiLeadRecommendationType.PROPERTY: {
                return selectedRecommendations.property.includes(recommendationId);
            }
            case MultiLeadRecommendationType.OFFER: {
                return selectedRecommendations.offer.includes(recommendationId);
            }
            default:
                return false;
        }
    };

    const toggleRecommendation = (recommendationId: number) => {
        const isSelected = isRecommendationSelected(recommendationId);

        switch (props.recommendationType) {
            case MultiLeadRecommendationType.PROPERTY:
                dispatchSelectedRecommendations({type: "property", payload: recommendationId});
                return !isSelected;
            case MultiLeadRecommendationType.OFFER:
                dispatchSelectedRecommendations({type: "offer", payload: recommendationId});
                return !isSelected;
            default:
                throw new Error("Unknown recommendation type");
        }
    };

    const selectAll = () => {
        switch (props.recommendationType) {
            case MultiLeadRecommendationType.PROPERTY:
                dispatchSelectedRecommendations({type: "property", payload: props.recommendedProperties.map((item) => item.id)});
                break;
            case MultiLeadRecommendationType.OFFER:
                dispatchSelectedRecommendations({type: "offer", payload: props.recommendedOffers.map((item) => item.id)});
                break;
            default:
                throw new Error("Unknown recommendation type");
        }
    };

    const resetSelection = () => {
        dispatchSelectedRecommendations({type: "reset"});
    };

    return {
        selectedRecommendations:
            props.recommendationType === MultiLeadRecommendationType.PROPERTY ? selectedRecommendations.property : selectedRecommendations.offer,
        isRecommendationSelected,
        selectedRecommendationsCount,
        toggleRecommendation,
        resetSelection
    };
};
