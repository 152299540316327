import React from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {borderRadius, flexAbsoluteCenter, mb, mt, onDesktop, p, pr} from "@pg-design/helpers-css";
import {CheckIcon} from "@pg-design/icons";
import {Image} from "@pg-design/image";
import {Text} from "@pg-design/text";
import {areaFormat, pluralize} from "@pg-mono/string-utils";
import {priceFormat} from "@pg-mono/string-utils";

import {getOfferTypeNameCapital} from "../../../../../offer/helpers/OfferType";
import {IRegionEstimatedPriceSummary} from "../../../../../region/hooks/use_region_estimated_price";
import {Country} from "../../../../../region/types/Country";
import {useEstimatedPriceByArea} from "../../../../hooks/use_estimated_price_by_area";
import {IMultiLeadData} from "../../../../types/IMultiLeadData";

interface IProps extends IMultiLeadData {
    regionEstimatedPriceSummary: IRegionEstimatedPriceSummary;
    className?: string;
}

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

export const MultiLeadApplicationSummaryBox = (props: IProps) => {
    const theme = useTheme();
    const displayType = getOfferTypeNameCapital(props.offerType);
    const {estimatedPriceMin, estimatedPriceMax} = useEstimatedPriceByArea(props);
    const priceFormatWithUnit = (price: number) => priceFormat(price, {unit: props.region.country === Country.POLAND ? "zł" : "eur"});

    return (
        <div css={wrapperStyle} className={props.className}>
            <div css={propertyImageColumn}>
                <Image css={propertyImage} src={props.mainImage.desktop} width="auto" height="auto" alt={`${props.offerVendorName} - ${props.offerName}`} />
            </div>
            <div css={contentStyle}>
                <Text variant="body_copy_1">
                    {props.rooms || props.area
                        ? `${displayType}, ${props.rooms ? `${props.rooms} ${roomsPluralize(props.rooms)}, ` : ""}${props.area ? areaFormat(props.area) : ""}`
                        : props.offerName}
                </Text>
                <Text variant="info_txt_3" color={theme.colors.gray[800]} css={mb(1)}>
                    {props.offerVendorName}
                </Text>
                <Text variant="info_txt_2" color={theme.colors.gray[800]} css={mb(2)}>
                    {props.offerAddress}
                </Text>

                {props.isPriceRevealed && props.price && props.price > 0 ? (
                    <>
                        <Text variant="info_txt_2" css={mb(0.5)}>
                            Cena:
                        </Text>
                        <Text variant="body_copy_2" css={estimatedPricesWrapperStyle}>
                            {priceFormatWithUnit(props.price)}
                        </Text>
                    </>
                ) : estimatedPriceMin || estimatedPriceMax ? (
                    <>
                        <Text variant="info_txt_2" css={mb(0.5)}>
                            Cena estymowana:
                        </Text>
                        <Text variant="body_copy_2" css={estimatedPricesWrapperStyle}>
                            {estimatedPriceMin ? priceFormatWithUnit(estimatedPriceMin) : null}
                            {estimatedPriceMin && estimatedPriceMax ? ` - ` : null}
                            {estimatedPriceMax ? priceFormatWithUnit(estimatedPriceMax) : null}
                        </Text>
                    </>
                ) : null}
            </div>
            <div css={[pr(1), onDesktop(pr(2)), flexAbsoluteCenter]}>
                <CheckIcon wrapperSize="2.4" size="2.4" wrapperColor={theme.colors.success} />
            </div>
        </div>
    );
};

const wrapperStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    overflow: hidden;
    background-color: ${theme.colors.gray[100]};
    width: 100%;
`;

const contentStyle = css`
    ${p(1)}
    flex: 1 1 auto;

    ${onDesktop(css`
        ${p(1, 2)};
    `)};
`;

const propertyImageColumn = css`
    width: 125px;
    flex: 0 0 125px;

    ${onDesktop(css`
        width: 160px;
        flex: 0 0 160px;
    `)};
`;

const propertyImage = css`
    width: 100%;
    height: 100%;

    & > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;

const estimatedPricesWrapperStyle = (theme: Theme) => css`
    display: inline-flex;
    background-color: ${theme.colors.success};
    ${borderRadius(2)};
    color: ${theme.colors.secondary};
    ${mt(1)};
    ${p(0.5, 1)};

    @media (max-width: ${theme.breakpoints.xs}) {
        font-size: 1rem;
    }
`;
