import React, {useEffect, useMemo, useState} from "react";
import {css} from "@emotion/react";

import {OSMGenericNearbyOffersMap} from "../../../../maps/components/generic_nearby_offers/open_street_maps/OSMGenericNearbyOffers";
import {Country} from "../../../../region/types/Country";
import {ViewType} from "../../../../view_type/ViewType";
import {IMultiLeadData} from "../../../types/IMultiLeadData";
import {MultiLeadRecommendationType} from "../../../types/MultiLeadRecommendationType";
import {getRecommendedMapIcon} from "../../../utils/recommended_application/get_recommended_map_icon";
import {transformMultiLeadOfferIntoMapOffer} from "../../../utils/recommended_application/transform_multi_lead_offer_into_map_offer";

const mainOfferPin = require("../../../../maps/images/pins/multi_lead_main_offer_pin.svg");

export interface IMultiLeadMapProps {
    checkedList: number[];
    className?: string;
    viewType: ViewType | null;
    originalGeoPoint?: {
        coordinates?: [number, number];
    };
    originalCountry: Country;
    boundsPadding?: [number, number];
    boundsPaddingTopLeft?: [number, number];
    boundsPaddingBottomRight?: [number, number];
    recommendations: IMultiLeadData[];
    handlePinClick?: (viewType: ViewType | null, recommendedItemID: number) => void;
    hoveredItemID: number | null;
    recommendationType: MultiLeadRecommendationType;
    additionalCircleRadiusInMeters?: number;
    disableRadiusCalcByMarkers?: boolean;
    disableCircleAroundMarkers?: boolean;
}

const MultiLeadMapC: React.FC<IMultiLeadMapProps> = (props) => {
    const {originalGeoPoint, originalCountry} = props;
    const [lastValidOriginCoordinates, setValidOriginCoordinates] = useState<[number, number] | null>(null);

    useEffect(() => {
        if (originalGeoPoint?.coordinates) {
            setValidOriginCoordinates(originalGeoPoint.coordinates);
        }
    }, [originalGeoPoint?.coordinates]);

    const getMarkerIcon = (offerId: number): string => {
        const isChecked = props.checkedList.includes(offerId);
        return getRecommendedMapIcon(isChecked);
    };

    const handleDesktopInfoWindowOpen = (recommendedItemID: string | number) => {
        const id = Number(recommendedItemID);

        if (Number.isInteger(id)) {
            props.handlePinClick?.(props.viewType, id);
        }
    };

    const offers = useMemo(() => {
        return props.recommendations.map((recommendation) => transformMultiLeadOfferIntoMapOffer(recommendation));
    }, [props.recommendations]);

    return lastValidOriginCoordinates ? (
        <div css={mapWrapper}>
            <OSMGenericNearbyOffersMap
                drawCircleAroundMarkers={!props.disableCircleAroundMarkers}
                viewType={props.viewType}
                hoveredItemID={props.hoveredItemID}
                additionalCircleRadiusInMeters={props.additionalCircleRadiusInMeters}
                disableRadiusCalcByMarkers={props.disableRadiusCalcByMarkers}
                originalMarkerCoordinates={lastValidOriginCoordinates}
                originalMarkerCountry={originalCountry}
                offers={offers}
                boundsPadding={props.boundsPadding}
                boundsPaddingTopLeft={props.boundsPaddingTopLeft}
                boundsPaddingBottomRight={props.boundsPaddingBottomRight}
                getMarkerIcon={getMarkerIcon}
                originalMarkerIcon={mainOfferPin}
                onDesktopInfoWindowOpen={handleDesktopInfoWindowOpen}
                onMobileInfoWindowOpen={handleDesktopInfoWindowOpen}
            />
        </div>
    ) : null;
};

export const MultiLeadMap = React.memo(MultiLeadMapC);

const mapWrapper = css`
    position: relative;
    height: 100%;
`;
