import {Dispatch} from "redux";

import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link} from "@pg-mono/rp-api-routes";

import {IRecommendedOffer} from "../../../recommendations/types/IRecommendedOffer";

const FETCH_RECOMMENDED_OFFERS_PREFIX = "similar_offers/FETCH_RECOMMENDED_OFFERS_PREFIX";
export const fetchRecommendedOfferTypes = createRequestActionTypes(FETCH_RECOMMENDED_OFFERS_PREFIX);

interface IRecommendedOffersResponse {
    results: IRecommendedOffer[];
}

export const fetchRecommendedOffers = (offerId: number, numberOfRooms: number[] | undefined | null, excludedOfferIds: number[]) => (dispatch: Dispatch) => {
    dispatch({type: fetchRecommendedOfferTypes.start});

    const query = {
        ...(!!numberOfRooms && numberOfRooms.length > 0 && {rooms: numberOfRooms.join(",")}),
        page_size: 6,
        exclude_offer: excludedOfferIds
    };

    const url = appendQueryString(apiV2Link.offer.similar({offerId}), query);

    return getRequest({}, url).then((json: IRecommendedOffersResponse) => {
        dispatch({type: fetchRecommendedOfferTypes.success, result: json.results});
        return json.results;
    });
};

export const resetRecommendedOffers = () => ({
    type: fetchRecommendedOfferTypes.reset
});
