import {areaFormat, pluralize} from "@pg-mono/string-utils";

import {IMapOffer} from "../../../maps/types/IMapOffer";
import {IRecommendedOffer} from "../../../recommendations/types/IRecommendedOffer";
import {IRecommendedProperty} from "../../../recommendations/types/IRecommendedProperty";

const roomsPluralize = pluralize(["pokój", "pokoje", "pokoi"]);

export const transformRecommendedPropertyIntoMapOffer = (offer: IRecommendedProperty): IMapOffer => ({
    id: offer.id,
    name: offer.offer.name,
    price: offer.price,
    coordinates: offer.offer.geo_point.coordinates,
    vendorName: offer.offer.vendor.name,
    vendorLogo: offer.offer.vendor.logo.v_log_80x60,
    description: `${offer.rooms} ${roomsPluralize(offer.rooms)}, ${areaFormat(offer.area)}`,
    mainImage: {
        desktop: offer.offer.main_image?.m_img_224x147,
        mobile: offer.offer.main_image?.m_img_155x87
    },
    distance: offer.distance,
    region: {
        country: offer.region.country
    }
});

export const transformRecommendedOfferIntoMapOffer = (offer: IRecommendedOffer): IMapOffer => ({
    id: offer.id,
    name: offer.name,
    coordinates: offer.geo_point.coordinates,
    vendorName: offer.vendor.name,
    vendorLogo: offer.vendor.logo?.v_log_80x60,
    description: "",
    mainImage: {
        desktop: offer.main_image?.m_img_277x160,
        mobile: offer.main_image?.m_img_155x87
    },
    distance: offer.distance,
    region: {
        country: offer.region.country
    }
});
