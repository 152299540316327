import React from "react";
import {css} from "@emotion/react";

import {calculateRemSize} from "@pg-design/helpers-css";

import {IRegionEstimatedPriceSummary} from "../../../../../region/hooks/use_region_estimated_price";
import {IMultiLeadData} from "../../../../types/IMultiLeadData";
import {MultiLeadApplicationSummaryBox} from "./MultiLeadApplicationSummaryBox";

interface IProps {
    applications: IMultiLeadData[];
    regionEstimatedPriceSummary: IRegionEstimatedPriceSummary;
    className?: string;
}

export const MultiLeadApplicationSummary = (props: IProps) => {
    return (
        <div css={wrapperStyle} className={props.className}>
            {props.applications.map((application) => {
                return (
                    <MultiLeadApplicationSummaryBox
                        key={application.id}
                        {...application}
                        regionEstimatedPriceSummary={props.regionEstimatedPriceSummary}
                        css={boxStyle}
                    />
                );
            })}
        </div>
    );
};

const wrapperStyle = css`
    display: flex;
    flex-direction: column;
    gap: ${calculateRemSize(1)};
    overflow: auto;
`;

const boxStyle = css`
    flex: 0 0 auto;
`;
