import {ApplicationVariant} from "../types/ApplicationVariant";

interface IProps {
    applicationVariant?: ApplicationVariant;
    offerId?: number;
    propertyId?: number;
}

export const useApplicationVariant = (props: IProps) => {
    const applicationVariant = props.applicationVariant ?? ApplicationVariant.PROPERTY_WITH_RETARGETING;

    if (applicationVariant === ApplicationVariant.PROPERTY_WITH_RETARGETING && !props.propertyId) {
        // PROPERTY_WITH_RETARGETING is only available for properties
        return ApplicationVariant.DEFAULT;
    }

    return applicationVariant;
};
