import {useEffect, useState} from "react";
import {css, Theme, useTheme} from "@emotion/react";

import {Button} from "@pg-design/button";
import {borderRadius, calculateRemSize, flex, flexDirection, mb, mt, onDesktop, p, pl, w100} from "@pg-design/helpers-css";
import {BrandCheckboxCheckedIcon} from "@pg-design/icons";
import {Image} from "@pg-design/image";
import {IRange, Range} from "@pg-design/range";
import {Text} from "@pg-design/text";
import {useUserDevice} from "@pg-mono/user-device";

import {IRegionEstimatedPriceSummary} from "../../../../../region/hooks/use_region_estimated_price";
import {Country} from "../../../../../region/types/Country";
import {DEFAULT_PRICE_OPTION, PRICE_STEP_CONFIG} from "../../../../constants/multi_lead_constants";
import {IPriceFilter} from "../../../../hooks/use_recommendations_filters";
import {IMultiLeadData} from "../../../../types/IMultiLeadData";
import {MultiLeadCardEstimatedPrice} from "./MultiLeadCardEstimatedPrice";
import {MultiLeadCardEstimatedPriceWrapper} from "./MultiLeadCardEstimatedPriceWrapper";

enum SummaryVariant {
    WITH_ESTIMATED_PRICE,
    SIMPLE
}

interface IProps extends IMultiLeadData {
    regionEstimatedPriceSummary: IRegionEstimatedPriceSummary;
    areRecommendationsFetching: boolean;
    hasBudgetBeenChanged?: boolean;
    onBudgetChange: (priceFilter: IPriceFilter) => void;
    onShowNextCard: () => void;
}

const SIMPLE_SUMMARY_SHOW_TIME = 2000;

export const MultiLeadCardSentSummary = (props: IProps) => {
    const [budget, setBudget] = useState<IPriceFilter>({...DEFAULT_PRICE_OPTION.value});
    const theme = useTheme();
    const {isMobile} = useUserDevice();
    const isAbroadOffer = !!props.region.country && props.region.country !== Country.POLAND;
    const summaryVariant = isAbroadOffer ? SummaryVariant.SIMPLE : SummaryVariant.WITH_ESTIMATED_PRICE;

    useEffect(() => {
        if (summaryVariant === SummaryVariant.SIMPLE) {
            const timeout = setTimeout(props.onShowNextCard, SIMPLE_SUMMARY_SHOW_TIME);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [summaryVariant]);

    const onBudgetChange = (_: string, value: IRange<number>) => {
        if (value.upper) {
            setBudget(value);
        }
    };

    const onBudgetApply = () => {
        if (budget) {
            props.onBudgetChange(budget);
        }
    };

    const iconSize = !isMobile && summaryVariant === SummaryVariant.SIMPLE ? "12.8" : "7.2";

    return (
        <div css={[p(1.5, 0.5, 2, 0.5)]}>
            <div css={summaryVariant === SummaryVariant.SIMPLE ? sentInfoWrapperBigStyle : sentInfoWrapperCompactStyle}>
                <div>
                    <BrandCheckboxCheckedIcon wrapperColor={theme.colors.primary} wrapperSize={iconSize} size={iconSize} />
                </div>
                <div css={pl(2)}>
                    <Text
                        variant={summaryVariant === SummaryVariant.SIMPLE ? "headline_4" : "headline_5"}
                        align={summaryVariant === SummaryVariant.SIMPLE ? "center" : "left"}
                        css={summaryVariant === SummaryVariant.SIMPLE ? mb(1) : ""}
                    >
                        Wysłano zapytanie
                    </Text>
                    <Text
                        variant={summaryVariant === SummaryVariant.SIMPLE ? "body_copy_1" : "body_copy_2"}
                        align={summaryVariant === SummaryVariant.SIMPLE ? "center" : "left"}
                        color={theme.colors.gray[800]}
                    >
                        Oczekuj na kontakt od {props.offerVendorName}.
                    </Text>
                </div>
            </div>

            {summaryVariant === SummaryVariant.WITH_ESTIMATED_PRICE ? (
                <MultiLeadCardEstimatedPriceWrapper
                    price={props.price}
                    isPriceRevealed={props.isPriceRevealed}
                    regionEstimatedPriceSummary={props.regionEstimatedPriceSummary}
                    area={props.area}
                >
                    {(estimatedPriceProps) => (
                        <>
                            <div css={estimatedPriceBoxStyle}>
                                <div css={propertyImageColumn}>
                                    <Image
                                        css={propertyImage}
                                        src={props.mainImage.desktop}
                                        width="auto"
                                        height="auto"
                                        alt={`${props.offerVendorName} - ${props.offerName}`}
                                    />
                                </div>

                                <MultiLeadCardEstimatedPrice {...props} estimatedPriceProps={estimatedPriceProps} />
                            </div>

                            {estimatedPriceProps.isEstimatedPriceLoading ? null : (
                                <div css={[mt(4)]}>
                                    {props.hasBudgetBeenChanged ? null : (
                                        <>
                                            <Text variant="body_copy_2" css={mb(1)}>
                                                Okreś swój budżet:
                                            </Text>
                                            <Range
                                                name="budget"
                                                onChange={onBudgetChange}
                                                min={PRICE_STEP_CONFIG.min}
                                                max={PRICE_STEP_CONFIG.max}
                                                step={PRICE_STEP_CONFIG.step}
                                                value={{...DEFAULT_PRICE_OPTION.value}}
                                            />
                                        </>
                                    )}

                                    <Button
                                        isLoading={props.areRecommendationsFetching}
                                        size="small"
                                        variant="filled_primary"
                                        onClick={onBudgetApply}
                                        css={[w100, mt(4)]}
                                    >
                                        OK
                                    </Button>
                                </div>
                            )}
                        </>
                    )}
                </MultiLeadCardEstimatedPriceWrapper>
            ) : (
                <Button isLoading={props.areRecommendationsFetching} size="small" variant="filled_primary" onClick={props.onShowNextCard} css={[w100, mt(4)]}>
                    OK
                </Button>
            )}
        </div>
    );
};

const sentInfoWrapperCompactStyle = css`
    ${mb(3)};
    ${flex("center")};
    ${flexDirection("row")};
`;

const sentInfoWrapperBigStyle = css`
    ${mt(5)};
    ${mb(6)};
    ${flex("center", "center")};
    ${flexDirection("column")};
    gap: ${calculateRemSize(1.5)};

    ${onDesktop(css`
        ${mt(8)};
        ${mb(8)};
    `)};
`;

const estimatedPriceBoxStyle = (theme: Theme) => css`
    display: flex;
    flex-direction: row;
    ${borderRadius(2)};
    overflow: hidden;
    background-color: ${theme.colors.gray[100]};
    width: 100%;
`;

const propertyImageColumn = css`
    height: 14rem;
    width: 10rem;

    ${onDesktop(css`
        height: 16rem;
        width: 15rem;
    `)};
`;

const propertyImage = css`
    width: 100%;
    height: 100%;

    & > img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;
