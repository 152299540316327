import {apiLink} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../app/api/rp_api";
import {IPaginatedResponse} from "../../request/IPaginatedResponse";
import {IArchitectListItem} from "../types/IArchitectListItem";
import {IArchitectListRegion} from "../types/IArchitectListRegion";

interface IGetArchitectListPayload {
    region?: number;
    page?: number;
    pageSize?: number;
}

export const ARCHITECT_LIST_PAGE_SIZE = 12;

export const readArchitectListApi = rpApi.injectEndpoints({
    endpoints: (builder) => ({
        readArchitectList: builder.query<IPaginatedResponse<IArchitectListItem>, IGetArchitectListPayload>({
            query: ({page = 1, region, pageSize}) => {
                const params: Record<string, string | number> = {page, page_size: pageSize || ARCHITECT_LIST_PAGE_SIZE};

                if (region) {
                    params.region = region;
                }

                return {
                    url: apiLink.marketplace.architect.list(),
                    params
                };
            }
        }),
        readArchitectListRegions: builder.query<IPaginatedResponse<IArchitectListRegion>, null>({
            query: () => {
                return {
                    url: apiLink.marketplace.region.list(),
                    params: {page_size: 1000}
                };
            }
        })
    })
});

export const selectArchitectListRegions = () => readArchitectListApi.endpoints.readArchitectListRegions.select(null);

export const {useReadArchitectListQuery, useReadArchitectListRegionsQuery} = readArchitectListApi;
