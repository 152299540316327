import * as React from "react";
import {useEffect} from "react";
import {connect} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {getSentLeadFormValuesFromStorage} from "../../lead/utils/sent_lead_form_values";
import {gtmApplicationPutAside} from "../../tracking/google_tag_manager/gtm_application_put_aside";
import {IApplicationData} from "../reducers/application_reducer";
import {ApplicationGoodbyeContent, IApplicationGoodbyeContentProps} from "./ApplicationGoodbyeContent";

interface IStateProps {
    data: IApplicationData | undefined;
}
interface IOwnProps extends IApplicationGoodbyeContentProps {}
interface IProps extends IStateProps, IOwnProps {}

const ApplicationGoodbyeContentModalC = (props: IProps) => {
    useEffect(() => {
        const sentLeadFormValues = getSentLeadFormValuesFromStorage();

        const areFormValuesAvailable =
            sentLeadFormValues && Boolean(sentLeadFormValues.name) && Boolean(sentLeadFormValues.email) && Boolean(sentLeadFormValues.phone);

        if (props.data && areFormValuesAvailable) {
            gtmApplicationPutAside(props.data.vendor?.detail, props.data.offer?.detail);
        }
    }, []);

    return <ApplicationGoodbyeContent {...props} />;
};

export const ApplicationGoodbyeContentModal = connect(mapStateToProps)(ApplicationGoodbyeContentModalC);

/**
 * Connect
 */
function mapStateToProps(state: IRPStore, props: IOwnProps): IStateProps {
    return {
        data: state.application[props.storeHash]
    };
}
